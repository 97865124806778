.local__tooltip,#actionColumnBtnTippyBlock {
	display: flex;
	align-items: center;
}

.local__tooltip .tippy-box, #actionColumnBtnTippyBlock .tippy-box{
	background: rgb(43, 43, 43) !important;
	color: #fff !important;
	border-radius: 8px !important;
	padding: 10px !important;
	font-size: 14px !important;
	font-weight: 600 !important;


	@media (min-width: 769px) and (max-width: 1440px) {
		border-radius: 6px !important;
		font-size: 11px !important;
		padding: 8px !important;
	}
}

.local__tooltip .tippy-content, #actionColumnBtnTippyBlock .tippy-content {
	padding: 0 !important;
	width: fit-content !important;
	color: #fff !important;
	a {
		color: #ddd !important;
	}
}

.local__tooltip .tippy-arrow, #actionColumnBtnTippyBlock .tippy-arrow {
	color: rgb(43, 43, 43) !important;
	background:rgb(43, 43, 43) !important;
}
