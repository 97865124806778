
@media (max-width: 768px) {
	#salesPageDatepickerBlockWrapper .react-datepicker-popper{
		width: 94%;
	}

	#salesPageDatepickerBlockWrapper .react-datepicker,.react-datepicker__month-container {
		width: 100%;
	}

	#salesPageDatepickerBlockWrapper .react-datepicker__day-names,.react-datepicker__week {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#salesPageDatepickerBlockWrapper .react-datepicker-wrapper:nth-of-type(2) + .react-datepicker__tab-loop .react-datepicker-popper {
		margin-right:-6px;
	}

	#salesPageDatepickerBlockWrapper .datePicker__wrapper {
		border-radius: 8px !important;
	}

}

