.tableMobileCard {
	background: #fff;
	border-radius: 8px;
	padding: 15px;
	margin: 0 auto;
	border: 1px solid #DDD;
	display: flex;
	flex-direction: column;
	gap:15px;
	margin-bottom: 15px;
	&__imageWithTitle {
		display: flex;
		align-items:stretch;
		gap: 15px;
	}


	&__image {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			border-radius: 8px;
		}
		svg {
			width: 120px;
			height: 120px;
			fill: #B6B6B6;
		}
	}

	&__title {
		font-size: 16px;
		font-weight: 600;
		line-height: 1.2em;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&__info {
		display: flex;
		align-items: flex-start;
		gap: 10px;

		.info__platform {
			width: 40px;
			height: 40px;
		}

		.info__notPlatform {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			background: #EFEFEF;
		}

		&-otherDetails {
			display: flex;
			flex-direction: column;
			gap: 5px;

		}

		&-otherDetail {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 14px;
			font-weight: 400;
		}

		&-otherDetailLabel {
			color: #767676;
		}
		&-otherDetailValue {
			color: #2B2B2B;
			word-break: break-word;
			overflow-wrap: break-word;
			white-space: normal;


			&--green {
				color: #32C09A;
			}
		}


	}


	&__select {
		margin-bottom: 5px;
	}

	&__actionsBtnWrapper {
		position: relative;
	}

	&__actionsBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 15px;
		color: #fff;
		background:#1E3166;
		padding: 10px 0;
		border-radius: 8px;
		cursor: pointer;

		span {
			font-size: 16px;
			font-weight: 600;
		}

		svg {
			path {
				fill: #fff;
			}
		}
	}

	&__actionsDropdown {
		position: absolute;
		width: 100%;
		bottom: 115%;
		background: #fff;
		z-index: 999;
		color: #1E3166;
		font-size: 16px;
		font-weight: 600;
		border: 1px solid #1E3166;
		border-radius: 8px;
	}

	&__actionsDropdownItem {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 15px;
		padding: 15px 20px;

		&:first-child {
			border-bottom: 1px solid #DDD;
		}

		svg {
			width: 20px;
			height: 20px;

		}

		&--link {
			svg {
				path {
					fill: #32C09A;
				}
			}
		}

		&--unlink, &--delete {
			svg {
				path {
					fill: #FF4D4D;
				}
			}
		}


	}

}

.dropdownArrowRotate {
	transform: rotate(180deg);
}

