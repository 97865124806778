.disabledMarketsOnSales .tippy-box,.picklistWrapper .tippy-box {
	border: 1px solid red !important;
	border-radius: 4px !important;
	padding: 10px !important;
	background-color: #fff !important;
	font-size: 14px !important;
	color: #000;
}
.disabledMarketsOnSales .tippy-content,.picklistWrapper .tippy-content {
	padding: 0 !important;
}

.disabledMarketsOnSales + button .tippy-box {
	display: none;
}

@media (min-width: 769px) and (max-width: 1440px)  {
	.disabledMarketsOnSales .tippy-box {
		padding: 8px !important;
		font-size: 11px;
	}

}


