.mobileViewFilters {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 99999999;

	&__modal {
		padding: 15px;
		background-color: #fff;
		width: 100%;
		height: 100%;
		z-index: 999;
		display: flex;
		flex-direction: column;
		gap: 15px;

		&-titleBlock {
			display: flex;
			align-items: center;
			justify-content: space-between;

			svg {
				cursor: pointer;
			}
		}

		&-title {
			color: #000;
			font-size: 20px;
			font-weight: 600;
		}

		&-line {
			width: 100%;
			background: #DDD;
			height: 1px;
		}

		&-selectBlock {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		&-filterTitle {
			color: #000;
			font-size: 16px;
			font-weight: 600;
		}

		&-buttonsBlock {
			margin-top: auto;
			display: flex;
			flex-direction: column;
			gap: 15px;
		}

		&-buttons {
			display: flex;
			align-items: center;
			gap: 15px;
			justify-content: center;
		}

		&-activePlatformsBlock {
			display: flex;
			align-items: center;
			gap: 15px;
			flex-wrap: wrap;
		}

		&-activePlatformItem {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 10px;
			border-radius: 4px;
			background: #EFEFEF;
			color: #2B2B2B;
			font-size: 14px;
			font-style: italic;
			font-weight: 400;

			svg {
				cursor: pointer;
			}
		}

		&-clearAllBtn {
			cursor: pointer;
			color: #2B2B2B;
			font-size: 14px;
			font-weight: 400;
			padding: 10px;
			border-radius: 4px;
			background:  #E2EAFF;
		}

	}

}