@import '@assets/styles/variables.scss';

.button {
	min-height: 39px;
	height: 39px;
	display: flex;
	gap: 10px;
	white-space: nowrap;
	align-items: center;
	justify-content: space-between;
	font-size: 16px ;
	cursor: pointer ;
	border: none ;
	border-radius: 8px;
	font-weight: 600;
	padding: 10px;
	transition: $transition;

	&--primary {
		background-color: $darkBlue;
		color: #fff;

		>  span + div {
			height: 10px;
			width: 10px;
			border-radius: 100%;
		}

		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #1E3166;
			box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.05);
			opacity: $globalOpacity;
		}

		&--disabled {
			background-color: #B6B6B6;
			opacity: 1;
			cursor: not-allowed;

			> span + div  {
				background: #fff;
			}
		}
	}

	&--primary2 {
		background: var(--LP-Light-Blue, #E2EAFF);
		color: $darkBlue;

		>  span + div {
			height: 10px;
			width: 10px;
			border-radius: 100%;
		}

		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #D9E2FC;
		}

		&--disabled {
			opacity: 0.5;
			background: var(--LP-Light-Blue, #E2EAFF);
			cursor: not-allowed;

			> span + div  {
				background: #fff;
			}
		}
	}

	&--secondary {
		background: $secondaryBgColor;
		color: #fff;

		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #4DB89E;
			box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.05);
		}

		&--disabled {
			background: #4DB89E;
			opacity: 0.5;
			box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.05);
			cursor: not-allowed;

		}
	}

	&--error {
		background: $dangerRed;
		color: #fff;

		&:hover {
			opacity: $globalOpacity;
		}

		&--disabled {
			cursor: not-allowed;
		}
	}

	&--errorNew {
		background: #FFDADA;
		color:#FF4D4D;

		&:hover {
			background: linear-gradient(0deg, rgba(255, 77, 77, 0.10) 0%, rgba(255, 77, 77, 0.10) 100%), #FFDADA;
		}

		&--disabled {
			opacity: 0.5;
			background: #FFDADA;
			cursor: not-allowed;
		}
	}

	&--errorNew {
		background: #FFDADA;
		color:#FF4D4D;

		&:hover {
			background: linear-gradient(0deg, rgba(255, 77, 77, 0.10) 0%, rgba(255, 77, 77, 0.10) 100%), #FFDADA;
		}

		&:disabled {
			opacity: 0.5;
			background: #FFDADA;
		}
	}

	&--transparent {
		background: transparent;

		&:hover {
			opacity: $globalOpacity;
		}

		&--disabled {
			cursor: not-allowed;
		}
	}

	&--warning {
		background:#F2711C;
		color:#FFF;

		&:hover {
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #F2711C;
		}

		&--disabled {
			opacity: 0.5;
			background: #F2711C;
			cursor: not-allowed;
		}
	}

	&--disabled {
		background-color: #B6B6B6; // old style
		opacity: 1;
		cursor: not-allowed;
		color: #fff;

		&:hover {
			opacity: $globalOpacity;
			box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.05);
			background-color: #B6B6B6;
		}

	}



	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 13px ;
		border-radius: 6px ;
		min-height: 32px ;
		height: 32px ;
		padding: 8px ;
		gap: 8px ;

		svg {
			width: 16px !important;
			height: 16px !important;
		}
	}

}