@media (min-width: 769px) and (max-width: 1440px) {
	.twoLevelPieChart {
		zoom: 0.8;
	}
}

.twoLevelPieChart {
	max-width: 900px;
	width: 100%;
}

.chartContainer {
	background: #fff;
	width: 100%;
	border-radius: 8px;
	border: 1px solid #CBCBCB;
	position: relative;
	height: 450px;
	padding: 15px;
}

.chartContainerMobile {
	background: #fff;
	width: 100%;
	height: 550px;
	border-radius: 8px;
	border: 1px solid #CBCBCB;
	position: relative;
	font-size: 16px;
	font-weight: 600;
	padding: 15px;
}

.noResults {
	width: 100%;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
}

.noResultsMobile {
	width: 100%;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
}

.cursorPointer {
	cursor: pointer;
}

.legendList {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 30px;

	@media (max-width: 768px) {
		display: flex;
		flex-wrap: wrap;
		gap: 6px;
		margin: 0;
	}
}

.legendItem {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 8px;
	border-radius: 8px;
	background: #F8F8F8;
	padding: 5px;

	@media (max-width: 768px) {
		margin-bottom: 0;
	}

}

.legendSvg {
	border-radius: 2px;

	@media (max-width: 768px) {
		width: 14px;
		height: 14px;
	}
}

.legendSpan {
	margin-left: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: #2B2B2B;
	font-weight: 400;

	@media (max-width: 768px) {
		margin-left: 6px;
		gap: 6px;
		font-size: 14px;
	}
}

.legendValue {
	color: #32C09A;
	font-weight: 600;
}
