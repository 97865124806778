.multiItemOrderMobileCard {
	background: #fff;
	margin-bottom: 15px;

	&__header {
		border: 1px solid #DDD;
		padding: 15px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;

		&-title {
			display: flex;
			align-items: center;
			gap: 10px;
			color: #363636;
			font-size: 16px;
			font-weight: 600;
		}

		&-badge {
			border-radius: 8px;
			background: #32C09A;
			color: #fff;
			font-size: 14px;
			padding: 2px 8px;
		}

		&-info {
			display: flex;
			align-items: flex-start;
			gap: 10px;

			&-details {
				display: flex;
				align-items: center;
				gap: 10px;
				flex-wrap: wrap;
			}
		}

		&-selectBlock {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 15px;
		}

		&-select {
			width: 100%;
		}

		&-delete {
			cursor: pointer;
			border-radius: 8px;
			background: #FFE5E6;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;

			svg {
				width: 18px;
				height: 18px;
			}
		}

		&-info-platform {
			width: 40px;
			height: 40px;
		}

		&-info-notPlatform {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			background: #EFEFEF;
		}
	}

	&__detail-item {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	&__detail-label {
		color: #949494;
		font-size: 14px;
		font-weight: 400;
	}

	&__detail-value {
		color: #2B2B2B;
		font-size: 14px;
		font-weight: 400;

		&--green {
			color:  #32C09A;
		}
	}

	&__order-listings {
		display: flex;
		flex-direction: column;
		gap: 15px;
		padding: 15px;
		border: 1px solid rgba(63, 209, 139, 0.60);
		border-top: none;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}

}

.orderListingCard {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__leftSide {
		display: flex;
		align-items: stretch;
		gap: 10px;
	}

	&__image {
		img {
			width: 80px;
			height: 80px;
			border-radius: 8px;
		}

		svg {
			width: 80px;
			height: 80px;
			fill: #B6B6B6;
		}
	}

	&__titleBlock {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&__title {
		color: #2B2B2B;
		font-size: 14px;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&__details {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&__price {
		color: #32C09A;
		font-size: 14px;
		font-weight: 600;
	}

	&__sku {
		display: flex;
		align-items: center;
		gap: 5px	;
		font-size: 14px;
		font-weight: 400;
		line-height: 129.182%;
		text-transform: capitalize;

		&-label {
			color: #949494;
		}

		&-value {
			color: #2B2B2B;
			word-break: break-word;
			overflow-wrap: break-word;
			white-space: normal;
		}
	}

	&__actionsBtnWrapper {
		position: relative;
	}

	&__actionsBtn {
		cursor: pointer;
		border-radius: 8px;
		background: #1E3166;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 44px;
		min-height: 44px;

		svg {
			path {
				fill: #fff;
			}
		}
	}

	&__actionsDropdown {
		width: 150px;
		position: absolute;
		bottom: 115%;
		right: 0;
		z-index: 99;
		border: 1px solid #1E3166;
		border-radius: 8px;
		background: #fff;
		color:#1E3166;
		font-size: 16px;
		font-weight: 600;
	}

	&__actionsDropdownItem {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 15px;
		padding: 15px 20px;

		svg {
			width: 20px;
			height: 20px;
		}


		&:first-child {
			border-bottom: 1px solid #DDD;
		}


		&--unlink {
			svg {
				path {
					fill: #FF4D4D;
				}
			}

		}

		&--link {
			svg {
				path {
					fill: #32C09A;
				}
			}

		}

	}




}

