.platformsRowWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 140px));
  gap: 0 8px;
  .customMarket{
    display: flex;
    align-items: center;
    margin: 3px 0;
    flex-direction: column;
    @media (min-width: 769px) and (max-width: 1440px) {
      margin: 2px 0;
    }
  }
  .customMarketWithPlatformId{
    display: flex;
    align-items: center;
    color: #2185D0;
    margin: 3px 0;
    @media (min-width: 769px) and (max-width: 1440px) {
     margin: 2px 0;
    }
  }
  .customMarketIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 4px;
    @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 3px;
        padding: 3px;
    }
  }
  .customMarketName{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.platformsWithNoTitle{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    .customMarketWithPlatformId{
      justify-content: center;
      flex-direction: column;
      font-size: 15px;
      font-weight: 400;
      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 12px;
      }
    }
    .customMarketIcon{
      width: 40px;
      height: 40px;
      svg{
        width: 20px;
        height: 20px;
      }
      @media (min-width: 769px) and (max-width: 1440px) {
        height: 32px !important;
        width: 32px !important;
        svg{
          width: 16px!important;
          height: 16px!important;
        }
      }
    }
    .customMarket{
      justify-content: center;
    }
    .customMarketName{
      font-weight: 400;
      font-size: 15px;
      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 12px;
      }
    }
    @media (min-width: 769px) and (max-width: 1440px) {
      gap: 0 6px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
    img {
      height: 40px;
      width: 40px;
      @media (min-width: 769px) and (max-width: 1440px) {
        height: 32px;
        width: 32px;
      }
    }
  }
  &.platformsWithTitle{
    overflow: hidden;
    .customMarketWithPlatformId {
      justify-content: flex-start;
      flex-direction: row;
      gap: 10px;
      font-size: 16px;
      font-weight: 600;
      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 8px;
        font-size: 13px;
        .customMarketName {
          font-size: 13px !important;
        }
      }
      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 8px;
        font-size: 13px;
        .customMarketName {
          font-size: 13px !important;
        }
      }
    }
    .customMarketIcon {
      width: 20px;
      height: 20px;
      @media (min-width: 769px) and (max-width: 1440px) {
        width: 16px;
        height: 16px;
      }
    }
    .customMarket {
      margin: 2px 0 !important;
      font-size: 13px !important;
      gap: 10px;
      justify-content: flex-start;
      flex-direction: row;
      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 8px;
        .customMarketName {
          font-size: 13px !important;
        }
      }

    }
    .customMarketName{
     font-weight: 600;
      font-size: 16px;
      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 13px;
      }
    }
  }
}
