.mobileSliderContainer {
	width: 100%;
	margin-bottom: 15px;

	.sliderContainer {
		margin-bottom: 30px;
	}

	.statsItem {
		border-radius: 4px;
		background: #EFEFEF;
		padding: 10px;
		color: #fff;
		text-transform: capitalize;
		white-space: nowrap !important;
		text-align: center;
		overflow: hidden;

		&--active {
			background: #32C09A;

			.statsItem__title,.statsItem__price  {
				color: #fff;
			}

		}

		&__title {
			color: #2B2B2B;
			font-size: 14px;
			font-weight: 400;
			overflow: hidden;
			text-overflow: ellipsis !important;
		}

		&__price {
			color: #32C09A;
			text-align: center;
			font-size: 16px;
			font-weight: 600;
		}
	}

	.customArrows {
		display: flex;
		justify-content: space-between;
		z-index: 999;
		.leftArrow , .rightArrow{
			background-color: #E2EAFF;
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			cursor: pointer;
			svg {
				cursor: pointer;
				path {
					fill: #1E3166 ;
				}
			}

			&.disabled {
				cursor: not-allowed;
				opacity: 0.5;

				svg {
					color: #D5D5D5 !important;
				}
			}
		}
	}

}