.modalContainer {
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(39, 55, 77, 0.5);
  &.isModal{
    width: 80vw;
    left: auto;
    right: 0;
    .modal{
      position: absolute !important;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      left: 0;
    }
  }
  @media (max-width: 768px) {
    padding: 0 10px;
  }
}

.barcodeImageContainer{
  height: 140px;
  width: 140px;
  border-radius: 9px;
  .imageFromBarcode{
    height: auto;
    width: 100%;
    border-radius: 8px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  @media only screen and (max-width: 768px) {
    height: 110px;
    width: 110px;
  }
}
.mainPriceContainer{
  margin-top: 30px;
  margin-bottom: 20px;
  .priceRangeContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: start;
    margin-bottom: 20px;
    max-width: 650px;
    .priceRangeInputTitleContainer{
      display: flex;
      justify-content: flex-start;
      align-items: start;
      gap: 10px;
      .priceRangeDescription{
        display: flex;
        width: 100%;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #E2FFF8;
        svg{
          min-width: 14px;
          min-height: 14px;
        }
        p{
          color: #007D5F;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span{
            font-weight: 600;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;

      }
    }
    .priceRangeInput {
      -webkit-appearance: none;
      width: 100%;
      height: 15px;
      border-radius: 8px;
      outline: none;
      transition: 0.2s ease-in-out;
    }
    .priceRangeInput::-webkit-slider-runnable-track {
      width: 100%;
      height: 15px;
      cursor: pointer;
      border-radius: 8px;
    }
    .priceRangeInput::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 30px;
      height: 30px;
      border: 3px solid #32C09A;
      border-radius: 50%;
      background: #E2FFF8;
      cursor: pointer;
      box-shadow: 0 0 0 1px #32C09A;
      margin-top: -6px;
    }
    .priceRangeInput::-moz-range-thumb {
      width: 30px;
      height: 30px;
      border: 3px solid #32C09A;
      border-radius: 50%;
      background: #EFEFEF;
      cursor: pointer;
      box-shadow: 0 0 0 1px #32C09A;
    }
    .priceRangeValueInput{
      position: relative;
      .inputPrevStymbol{
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        font-size: 14px;
        color:rgba(0, 0, 0, 0.54) ;
      }
      .priceInput{
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        resize: none;
        width: 100%;
        max-width: 120px;
        height: 40px;
        padding-left: 18px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: rgba(0, 0, 0, 0.54);
        &:focus{
          border: 1px solid rgba(0, 0, 0, 0.54);
          outline:none;
        }
        /* Hide arrows in Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;

      }
    }



    .priceRangeSliderContainer{
      width: 100%;
      height: 20px;
      position: relative;
      .sliderValue{
        position: absolute;
        top: -50px;
        left: 50.5%;
        background: #333;
        color: #fff;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 8px;
        min-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        &::after{
          content: "";
          position: absolute;
          bottom: -18px; /* Adjust distance */
          left: 50%;
          transform: translateX(-50%);
          border-width: 10px;
          border-style: solid;
          border-color: #333 transparent transparent transparent;
        }
      }
    }
    .priceValuesContainer{
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .priceValue{
        color: #767676;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    @media only screen and (max-width: 768px){
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }
  }
}

.toggleButtonCondition{
  padding: 10px;
  font-size: 16px;
  font-style: normal;
  border-radius: 8px;
  background-color: #1e3166;
  cursor: pointer;
  border: none;
  color: #fff;
  &.inactive{
    background-color: #d7e2ff;
    color: #1e3166;
  }
}
.suggestModalContainer {
  width: 100%;
}
.charactersCount {
  color: #696969;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
.modalContainer {
  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999999;
    border-radius: 8px;
    border: 1px solid #b6b6b6;
    background: #f8f8f8;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    @media only screen and (max-width: 1200px) {
      max-width: calc(100% - 20px);
    }
    @media only screen and (max-width: 768px) {
      position: fixed;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      flex-direction: column;
      max-width: calc(100% - 20px);

    }
    .scrollContainerDescModal{
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      padding-right: 20px;
    }
    .innerContainer {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
      height: fit-content;
      overflow: hidden;
      flex-grow: 1;
    }
    .closeIcon {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
    h1 {
      margin-bottom: 20px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .textAreaTitle {
      margin-bottom: 5px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-top:10px;
      line-height: normal;
    }
    .footerButtons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 28px;
      .confirmationButtonRefuce {
        cursor: pointer !important;
        padding: 9px 30px;
        font-family: 'Open Sans';
        color: #1e3166;
        border-radius: 8px;
        border: 1px solid var(--Dark-Blue, #1e3166);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }
      .confirmationButtonAccept {
        -webkit-appearance: none; /* Reset Webkit's default styles */
        appearance: none; /* Reset general appearance */
        border-radius: 8px; /* Your desired border radius */
        background-color: #1e3166;
        color: white;
        cursor: pointer !important;
        border: none;
        padding: 9px 30px;
        font-size: 16px;
      }
    }
    .keywordsCount {
      color: #696969;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
    .detailSection {
      display: flex;
      align-items: flex-start;
      gap: 35px;
      justify-content: space-between;
      margin-top: 10px;
      @media only screen and (max-width: 768px) {
        gap: 10px;
      }
      &.detailsSectionUp {
        .confirmationButtons {
          .confirmationButtonRefuce {
            svg {
              margin-top: 5px !important;
            }
          }
        }
      }
      .confirmationButtons {
        display: flex;
        align-items: center;
        gap: 20px;
        .acceptContainer {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          .acceptGreenButton {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer !important;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: none;
            background: #4db89e;
          }
          .acceptImage {
            position: absolute;
            width: 28px;
            height: 20px;
            pointer-events: none;
            background: url('https://cdn.listperfectly.com/app.listperfectly.com/react-image-uploader/images/checkmark.svg')
            no-repeat;
            background-size: cover;
            cursor: pointer;
          }
        }

        .confirmationButtonRefuce {
          cursor: pointer !important;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          border: none;
          background-color: #b6b6b6;
          svg {
            width: 28px;
            height: 20px;
            fill: #000000;
            g {
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }

    .textarea {
      font-family: 'Open Sans';
      border: 1px solid #b6b6b6;
      border-radius: 8px;
      resize: none;
      width: 100%;
      height: 200px;
      padding: 15px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .input {
      font-family: 'Open Sans';
      border: 1px solid #b6b6b6;
      border-radius: 8px;
      resize: none;
      width: 100%;
      height: 50px;
      padding: 15px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
