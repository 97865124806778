@import "@assets/styles/variables.scss" ;

.cardContainer {
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin: 10px 0;
    position: relative;
    .platformsIssues{
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .crossPostingActive {
        color: #000;
        padding: 10px;
        font-size: 16px;
    }

    .soldIcon {
        height: 30px;
        padding: 10px 15px;
        background-color: #ff4d4d;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        max-width: 63px;
        font-size: 16px;
        text-transform: capitalize;
        position: absolute;
        left: 23px;
        top: 22px;
        z-index: 9;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        width: 100%;

        img {
            border-radius: 8px 8px 0 0;
        }

        div {
            svg {
                width: 100%;
                height: 300px;
                fill: #B6B6B6;
            }
        }
    }

    .bottomContainer {
        padding: 0 15px 15px 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 10px;
    }

    .title {
        color: var(--Off-Black, #363636);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 129.182%;
        text-transform: capitalize;
        word-break: break-all;
        a {
            transition: $transition;

            &:hover {
                opacity: $globalOpacity;
            }
        }
        p {
            word-break: break-word;
        }
    }

    .platformsWrapper {
        display: flex;
        gap: 0 8px;
        flex-wrap: wrap;
        img {
            height: 40px;
            width: 40px;
        }
    }

    .cardInfo {
        display: flex;
        flex-direction: column;
        gap: 10px;

        :first-child {
            &.infoCintainer {
                margin-right: 30px;
            }
        }

        .infoCintainer {
            display: flex;
            gap: 5px;
            align-items: center;
            .infoContainerTitle {
                color: #949494;
            }
        }

        p {
            color: #949494;
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 129.182%;
            /* 20.669px */
            text-transform: capitalize;
        }

        span {
            color: var(--Dark, #393838);
            font-family: Open Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 129.182%;
            text-transform: capitalize;
        }
    }

    .cardFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footerPrice {
            color: var(--Mint, #4db89e);
            font-family: Open Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 129.182%;
            /* 25.836px */
            text-transform: capitalize;
        }
    }
}

.actions {
    border: 1px solid #1e3166;
    border-radius: 8px;
    color: #1e3166;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    padding: 7px 15px;
    min-width: 180px;
    cursor: pointer;
    transition: $transition;

    &:hover {
        opacity: $globalOpacity;
    }
}

.actionsActive {
    background-color: #1e3166;
    border: 1px solid #fff;
    border-radius: 8px;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    padding: 7px 15px;
    min-width: 180px;
    cursor: pointer;
}

.dropdownBtnInactive {
    position: absolute;
    right: 12px;
    top: 10px;
    transform: rotate(180deg);
}

.dropdownBtnActive {
    position: absolute;
    right: 12px;
    top: 6px;
    transform: rotate(180deg);
}

.actionsMenu {
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;

    .actionsMenuItem {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        cursor: pointer;
        transition: $transition;

        &:first-child {
            @media only screen and (max-width: 768px) {
                p {
                    font-style: italic;
                }
                svg {
                    path {
                        fill: #b6b6b6;
                    }
                }
            }
        }

        &:hover {
            background-color: #4db89e;

            svg {
                path {
                    fill: #fff;
                }
            }
        }

        &:last-child {
            border-top: 1px solid #d2d2d2;
        }

        p {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            color: #393838;
            margin-left: 20px;
        }
        .disabledDeleteIcon{
            path{
                fill: #B6B6B6;
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1440px) {
    .imageContainer {
        div {
            svg {
                height: 240px !important;
            }
        }
    }
    .infoCintainer {
        gap: 4px;
    }
    .cardContainer {
        gap: 8px;
        margin: 8px 0;
        border-radius: 6px;
        .title {
            font-size: 16px;
            line-height: 103.34%;

        }
        .platformsIssues {
            gap: 6px;
        }
        .cardInfo {
            gap:8px;

            .infoCintainer {
                margin-right: 24px !important;
                gap:4px !important;

                .infoContainerTitle {
                   font-size: 13px;
                    line-height: 103.34%;
                }

                span {
                    font-size: 13px;
                    line-height: 103.34%;
                }

            }
        }
        .cardFooter {
            .footerPrice {
                font-size: 16px ;
                line-height: 103.34%;
            }
            .actions, .actionsActive {
                font-size: 16px;
                padding: 6px 12px;
                min-width: 144px;
            }

            .actions {
                border-radius: 6px !important;

                .dropdownBtnInactive {
                    right: 10px;
                    top: 8px;
                }
            }

            .actionsMenu {
                .actionsMenuItem {
                    padding: 12px 16px;
                    svg {
                        width: 24px;
                        height: 24px;
                    }

                    p {
                        font-size: 16px;
                        margin-left: 16px;
                    }
                }
            }
        }
        .soldIcon {
            min-width: 50px;
        }
    }

}
