// Variables
$modal-background: #fff;
$modal-border-radius: 8px;
$modal-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
$font-color: #888;
$button-bg-color: #3b82f6;
$button-bg-hover: #1d4ed8;
$font-family: Arial, sans-serif;
$overlay-background: rgba(0, 0, 0, 0.5);

// Overlay (Background behind modal)
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-background;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

// Modal Container
.shippingLabelModal {
  background-color: #f8f8f8;
  padding: 20px 40px 0 40px;
  max-width: 720px;
  min-height: 450px;
  height: 100%;
  width: 90%;
  border-radius: $modal-border-radius;
  box-shadow: $modal-shadow;
  font-family: $font-family;
  position: relative;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100% - 300px);
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    padding: 35px 15px 0 15px;
    min-height: 400px;
    overflow-x: hidden;
  }

}

.modalContent{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .content{
    h2{
      word-break: break-word;
    }
  }
}
// Header Section
.labelHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin: 0;
  }

  p {
    color: $font-color;
  }
}

// Dropdown
.labelHeader {
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.dropdown {
  position: relative;
  width: 100%;

  .title {
    color: #2B2B2B;
    font-size: 16px;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      font-size: 13px;
    }
  }
}

.dropdownToggle {
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  background-color: #EFEFEF;
  text-align: left;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;

  &.active {
    border-top: 1px solid #32C09A;
    border-left: 1px solid #32C09A;
    border-right: 1px solid #32C09A;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
  }

  .arrowDropdown {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    transition: all 0.5s;

    svg {
      width: 20px;
    }

    &.arrowDropdownActive {
      transform: rotate(180deg);
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 30px 10px 10px;
    gap: 5px;
    .arrowDropdown {
      right: 8px;
      svg {
        width: 15px;
      }
    }
  }
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #32C09A;
  border-left: 1px solid #32C09A;
  border-right: 1px solid #32C09A;
  border-radius: 0 0 8px 8px;
  background-color: #EFEFEF;
  z-index: 1000;
  max-height: 320px;
  overflow-y: auto;
  padding-bottom: 20px;
  margin-top: -10px;

  @media only screen and (max-width: 768px) {
    max-height: 300px;
  }
}

.dropdownItem {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  @media only screen and (max-width: 768px) {
    gap: 5px;
    padding: 10px;
  }
  &:hover {
    background-color: #E2EAFF;
  }
}

.subtitle {
  color: #767676;
  font-size: 14px;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

// PDF Viewer (Iframe Preview)
.pdfViewer {
  margin: 20px 0;
  text-align: center;
  font-size: 14px;

  iframe, object {
    border: none;
    width: 100%;
    height: 350px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    iframe, object {
      height: 300px;
    }
  }

  @media (max-width: 480px) {
    iframe, object {
      height: 200px;
    }
  }
}

.closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
  }
  @media only screen and (max-width: 768px) {
    right: 10px;
    top: 10px;
  }
}
// Button Group
.buttonGroup {
  display: flex;
  gap: 20px;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;

  a {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .downloadButton, .shareLinkButton {
    background-color: $button-bg-color;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: background-color 0.3s;
    max-width: 150px;

    &:hover {
      background-color: $button-bg-hover;
    }
  }

  .downloadButton {
    background-color: #1E3166;
    gap: 10px;
  }

  .shareLinkButton {
    background-color: #32C09A;
  }
}

.modalContainer {
  text-align: center;
}

.labelContainer {
  margin: 20px 0;
  font-size: 18px;
  background-color: #fff;
  overflow: hidden;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  padding: 10px;
}

.dropdownSelect {
  font-size: 16px;
  padding: 10px;
}

.preview img {
  //max-width: 100%;
  //height: auto;
  //margin-top: 20px;
}