.mainInfoContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }

  .titleLeft {
    display: flex;
    gap: 15px;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: none;
      padding: 10px 20px;
    }
  }
  .titleText {
    color: #2B2B2B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    word-break: break-word;
    @media screen and (max-width: 768px) {
      color: #393838;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .listingStatusesContainer{
    display: flex;
    gap:10px;
  }
  .listingStatusSinglView{
    height: 32px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding: 0 15px;
    width: fit-content;
    flex-shrink: 0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.sold{
      background-color: #ca1010;
    }
    &.draft{
      background-color: #73D9F9;
    }
    &.unlisted{
      background-color: #B6B6B6;
    }
    &.outOfStock{
      background-color: #fbbe0a;
    }
    &.active{
      background-color: #4db89e;
    }
    @media screen and (max-width: 768px) {
      display: flex;
    }
  }
  .price {
    color: #4db89e;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media screen and (max-width: 768px) {
      color: #4db89e;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .titleRight {
    display: flex;
    gap: 15px;
    padding: 15px 0;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 20px;
      row-gap: 20px;
    }
  }

  .printViewSku, .printViewQuantity{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  }
  .printViewSku{
    @media screen and (max-width: 768px) {
     flex-wrap: wrap;
    }
  }
  .sellerNotesContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
  }

  .skuContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    .skuLeftContainer{
      display: flex;
      align-items: flex-start;
      gap: 20px;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }


  .titleObjectKeyNameSKU {
    color: var(--Grey, #767676);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-all;
  }
  .titleObjectValueSKU {
    color: var(--Dark, #393838);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    word-break: break-all;
  }
}

