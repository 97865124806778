@import '../../../../assets/styles/variables.scss';
.statsWrapper {
	display: flex;
	gap: 20px;

	@media (max-width: 1200px) {
		flex-wrap: wrap;
	}

	.stats {
	padding: 30px;
	border-radius: 8px;
	border: 1px solid #CBCBCB;
	background: #fff;
	display: flex;
	justify-content: center;
	width: 450px;
	height: 452px;
	gap: 20px;
	user-select: none;

	.statsHeader {
		display: flex;
		align-items: center;
		gap: 15px;
		margin-bottom: 20px;

		h1 {
			color: #000;
			font-family: $fontFamilyOpenSans;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
		}

		a {
			color: #767676;
			font-family: $fontFamilyOpenSans;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: capitalize;
		}
	}

	.statsColumnsWrapper {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 15px;
		justify-items: center;

		.statsItem {
			border-radius: 8px;
			background: #F8F8F8;
			padding: 15px 0;
			min-width: 190px;
			cursor: pointer;

			h3 {
				color: #000;
				font-family: $fontFamilyOpenSans;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: capitalize;
				text-align: center;
				margin: 0 auto;
				margin-bottom: 20px;

			}

			h4 {
				color: #4DB89E;
				font-family: $fontFamilyOpenSans;
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: capitalize;
				margin: 0 auto;
				text-align: center;
			}
		}
	}


}

}
.activeStatsItem {
	background-color: #4DB89E;
	border-radius: 8px;
	padding: 15px 0;
	min-width: 190px;
	cursor: pointer;

	h3 {
		color: #fff;
		font-family: $fontFamilyOpenSans;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: capitalize;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	h4 {
		color: #fff;
		font-family: $fontFamilyOpenSans;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: capitalize;
		margin: 0 auto;
		text-align: center;
	}
}

@media (min-width: 769px) and (max-width: 1440px) {
	.statsWrapper {
		gap: 16px;

		.stats {
			padding: 24px ;
			gap: 16px;
			height: 361.5px;
			width: 360px;
			border-radius: 6px	;

			.statsHeader {
				gap: 12px;
				margin-bottom: 16px;

				h1 {
					font-size: 16px;
				}

				a {
					font-size: 13px;
				}
			}
			.statsColumnsWrapper {
				gap: 12px;

				.statsItem,.activeStatsItem {
					padding: 12px 0;
					min-width: 152px;
					border-radius: 6px;


					h3 {
						font-size: 13px;
						margin-bottom: 16px;
					}

					h4 {
						font-size: 19px;
					}
				}
			}
		}
	}
}
