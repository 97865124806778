
.close-image-icon, .minimize-modal-window{
    z-index: 10000;
    cursor: pointer;
}

/*custom tooltip*/
.custom-tooltip{
    position: relative;
    display: inline-block;
}

.custom-tooltip-big{
    position: relative;
    display: inline-block;
}

.no-scroll{
    overflow: hidden !important;
}

.custom-tooltip-big:hover::after{
    position: absolute;
    content: attr(data-tooltip);
    bottom: 2.5em;
    left: -2em;
    background-color: #656565;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    color: white;
    padding: .25em .5em;
    font-size: .8em;
    width: 300px;
}

.custom-tooltip:hover::after {
    position: absolute;
    content: attr(data-tooltip);
    bottom: -2.5em;
    right: -1.2em;
    background-color: #656565;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    color: white;
    padding: .25em .5em;
    font-size: .8em;
}

.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
}

.loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@-webkit-keyframes color {
    0% {
        stroke: #234165;
    }
    40% {
        stroke: #234165;
    }
    66% {
        stroke: #234165;
    }
    80%, 90% {
        stroke: #234165;
    }
}

@keyframes color {
    0% {
        stroke: #234165;
    }
    40% {
        stroke: #234165;
    }
    66% {
        stroke: #234165;
    }
    80%, 90% {
        stroke: #234165;
    }
}


.image-uploader-container *{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: none !important;
    touch-action: none !important;
    -webkit-transition: all .15s;
    -o-transition: all .15s;
    transition: all .15s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.image-uploader-actions *{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-touch-action: none !important;
    touch-action: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}

.image-filter-container * {
    -ms-touch-action: manipulation !important;
    touch-action: manipulation !important;
}

#wpadminbar {
    z-index: 80 !important;
}

.float-action {
    z-index: 900 !important;
}

.filters-web-tab-list{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
    line-height: initial !important;
}

@-webkit-keyframes bouncy {
    0% { -webkit-transform: scale(.1); transform: scale(.1); opacity: 0 }
    80% { -webkit-transform: scale(1.15); transform: scale(1.15); opacity: 1 }
    90% { -webkit-transform: scale(.9); transform: scale(.9); }
    100% { -webkit-transform: scale(1); transform: scale(1); }
}

@keyframes bouncy {
    0% { -webkit-transform: scale(.1); transform: scale(.1); opacity: 0 }
    80% { -webkit-transform: scale(1.15); transform: scale(1.15); opacity: 1 }
    90% { -webkit-transform: scale(.9); transform: scale(.9); }
    100% { -webkit-transform: scale(1); transform: scale(1); }
}
.image-uploader-status-title{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    color: #007D5F;
    background-color: #D0F4EB;
    border-radius: 8px;
    font-weight: 600;
    padding: 5px 15px;
    justify-content: flex-start;
    width: fit-content;
}
.uploaded-images-length{
    margin-left: 30px;
}
.container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 20px 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

#image-uploader{
    width: 100%;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
}

#image-uploader > .LPE_sizeLy{
    position: absolute !important;
    top: -69px !important;
    right: 0 !important;
    width: 104px !important;
    height: 58px !important;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.image-uploader-container{
    min-height: 240px;
    padding: 40px 20px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    position: relative !important;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
        max-width: 85%;
        margin: 0px auto;
        border: none !important;
    }
}

.lp_custom_chrome_extension_image_uploader_size_ly_logo {

    position: absolute;
    right: 20px;
    top:20px;
    cursor: pointer;
    z-index: 1;
    img {
        width: 70px;
        height: 39px;
    }
}
@media only screen and (min-width: 900px) {
    .image-uploader-container::before{
        content: "";
        position: absolute;
        border: 9px dashed #1E3166;
        top: -7px;
        bottom: -7px;
        left: -7px;
        right: -7px;
    }
    .uploader-desk-titles:not(.empty) .drag-image{
        width: 24px;
        height: 22px;
        background-size: contain;
        margin-bottom: 0;
    }
}

.uploadedImagePreview{
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-position: center;
}

.uploadedImageList{
    display: -ms-grid;
    display: grid;
    --repeat: auto-fit;
    -ms-grid-rows: 190px;
    grid-template-rows: 190px;
    grid-template-columns: repeat(var(--repeat, auto-fit), 190px);
    grid-gap: 8px;
    min-height: 250px;
    padding: 10px;

    @media only screen and (max-width: 768px){
        grid-gap: 4px;
    }
}

.uploadedImageList.pd-50{
    padding-top: 50px;
}

.coverShotImageItem{
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-row: 1 / 3;
    padding: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    height: 386px;
    border: 2px solid #d9d9d9;
    width: 385px;
    font-size: 14px;
    position: relative;
}

@media (min-width: calc(250px * 3)) {
    .grid {
        --repeat: 3;
    }
}


@keyframes animationFrames{
    0% {
        opacity:0;
        -webkit-transform:  translate(-800px,0px)  ;
        transform:  translate(-800px,0px)  ;
    }
    60% {
        opacity:1;
        -webkit-transform:  translate(10px,0px)  ;
        transform:  translate(10px,0px)  ;
    }
    80% {
        -webkit-transform:  translate(-4px,0px)  ;
        transform:  translate(-4px,0px)  ;
    }
    100% {
        opacity:1;
        -webkit-transform:  translate(0px,0px)  ;
        transform:  translate(0px,0px)  ;
    }
}

@-webkit-keyframes animationFrames {
    0% {
        opacity:0;
        -webkit-transform:  translate(-800px,0px)  ;
    }
    60% {
        opacity:1;
        -webkit-transform:  translate(10px,0px)  ;
    }
    80% {
        -webkit-transform:  translate(-4px,0px)  ;
    }
    100% {
        opacity:1;
        -webkit-transform:  translate(0px,0px)  ;
    }
}

.animated-display{
    animation: animationFrames ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:forwards; /*when the spec is finished*/
    -webkit-animation: animationFrames ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/
    -moz-animation: animationFrames ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: animationFrames ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: animationFrames ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/

}
.uploadedImageItem{
    padding: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    height: 188px;
    border: 1px solid #d9d9d9;
    width: 188px;
    font-size: 14px;
    z-index: 2;
    position: relative;
}

#imageUploadInput{
    display: none;
}

.uploader-title {
    margin-bottom: 9px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #393838;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
        font-size: 16px;
    }
}

.imageUploaderContainer{
    height: 188px;
    cursor: pointer;
    padding: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 188px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: #E4EBFF;
}
.imageUploaderContainerMob{
    display: none;
}
@media only screen and (max-width: 768px) {
   .imageUploaderContainerMob{
       display: flex;
   }
    .uploadedImageList.pd-50{
        padding: 5px !important;
    }
}
.imageUploaderFocused{
    border: 2px dashed #4EB99E;
}

.uploadedImageError{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.error-filename{
    color: #de4546;
    font-size: 14px;
    position: relative;
    top: 20px;
    text-align: center !important;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 165px;
}

.upload-image-icon{
    width: 40px;
    height: 40px;
    background: url("../images/upload.png") no-repeat;
    background-size: cover;
}

.open-uploader-settings{
    width: 40px;
    height: 40px;
    background: url("../images/settings.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    left: 27px;
    bottom: 17px;
}

.history-image-icon, .close-image-icon {
    width: 40px !important;
    height: 40px !important;
    position: relative;
    right: -10px;
    top: -3px;
    background: url("../images/history.svg") no-repeat;
    background-size: cover;
}

.close-image-icon-sm{
    width: 22px !important;
    height: 22px !important;
}

.close-image-icon {
    background: url("../images/close.svg") no-repeat;
    background-size: 30px !important;
    background-position: center;
    position: relative;
    right: 1px;
    top: 0;
}

.minimize-modal-window{
    background: url("../images/minimize.svg") no-repeat;
    background-size: 30px !important;
    background-position: center;
    position: relative;
    right: 1px;
    height: 40px !important;
    width: 40px !important;
    top: 0;
    margin-right: 10px;
}

.modal-header-right{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.remove-image-button {
    width: 26.25px;
    height: 30px;
    background: url("../images/trash-new.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    display:none;
}

.s3-image-button {
    width: 30px;
    height: 23.75px;
    background: url("../images/link-new.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 10px;
    display: none;
}



.s3-image-upload-status-loader {
    position: absolute;
    right: -17px;
    top: -10px;
    z-index: 9999;
    border: 16px solid #4db89e;
    border-radius: 50%;
    width: 30px;
    height: 30px; /* Changed height to make it a perfect circle */
    -webkit-animation: imageStatusLoader 2s linear infinite; /* Safari */
    animation: imageStatusLoader 2s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4db89e;
}

.s3-image-upload-status-loader img {
    height: 22px;
    width: auto;
    -webkit-animation: imageRotate 2s linear infinite; /* Safari */
    animation: imageRotate 2s linear infinite;
}

@-webkit-keyframes imageStatusLoader {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes imageStatusLoader {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes imageRotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes imageRotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.google-lens-button {
    width: 30px;
    height: 30px;
    background: url("../images/search-web-new.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    right:10px;
    top: 10px;
    display: none;
}

.main-star-icon{
    width: 24px;
    height: 24px;
    background: url(../images/main-star-new.svg) no-repeat;
    background-size: cover;
    position: absolute;
    z-index: 99;
    /*bottom: -5px;*/
    /*left: -8.5px;*/
    bottom: 1px;
    left: -2.5px;
}

/*.main-star-icon{*/
/*    width: 34px;*/
/*    height: 30px;*/
/*    background: url("../images/main-star.svg") no-repeat;*/
/*    background-size: cover;*/
/*    position: absolute;*/
/*    right: 8px;*/
/*    z-index: 99;*/
/*    top: 8px;*/
/*}*/
.mobile-image-actions-modal-overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #9a9a9f;
    z-index: 1000;
    opacity: 0.8;
}
.mobile-image-actions-modal{
    position: fixed;
    bottom: -2px;
    width: calc(100% - 40px);
    left: 20px;
    display: flex;
    max-height: 98%;
    height: 405px;
    flex-direction: column;
    z-index: 9999;
}
.mobile-image-actions-modal-actions{
    background: #f6f6f6;
    position: relative;
    top: -1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}
.mobile-image-actions-modal-header{
    background: #f6f6f6;
    border-radius: 8px 8px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    height: 100%;
    position: relative;
}
.mobile-image-actions-image{
    width: calc(100% - 40px);
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.mobile-image-actions-image-left,.mobile-image-actions-image-right{
    background-image: url("../images/scroll_arrow.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    position: absolute;
    top: calc(50% - 20px);
    height: 40px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.mobile-image-actions-image-counter{
    color: #767676;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 6px;
}
.mobile-image-actions-image-left.disabled,.mobile-image-actions-image-right.disabled{
    opacity: 0.7;
    pointer-events: none;
}

.mobile-image-actions-image-left{
    left: 10px;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    z-index: 2;
}

.mobile-image-actions-image-right{
    right: 10px;
    z-index: 2;
}


.mobile-image-actions-modal-actions-item{
    height: 60px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    width: calc(100% - 40px);
    color: #2997ff;
    border: 1px solid #666;
    margin-bottom: 16px;
    border-radius: 8px;
}
.mobile-image-actions-modal-actions-item.disabled{
    background: #e6e6e6;
    color: #b7b7b7;
    border-color: #b7b7b7 !important;
    pointer-events: none;
}
.mobile-image-actions-modal-actions-item:last-child{
    margin-bottom: 0;
}

.mobile-image-actions-modal-actions-item:nth-child(1){
    color: #4DB89E;
    border-color: #4DB89E;
}
.mobile-image-actions-modal-actions-item:nth-child(2){
    color: #FFA500;
    border-color: #FFA500;
}

.mobile-image-actions-modal-actions-item:nth-child(3){
    color: #2185D0;
    border-color: #2185D0;
}

.mobile-image-actions-modal-actions-item:nth-child(4){
    color: #FF4D4D;
    border-color: #FF4D4D;
}

.mobile-image-actions-modal-actions-item:nth-child(5){
    color: #666;
    border-color: #666;
}

.mobile-image-action-icon-remove{
    width: 21px;
    height: 24px;
    margin-right: 8px;
    background: url("../images/remove_icon_mobile.svg") no-repeat;
}
.mobile-image-action-icon-search{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url("../images/search_icon_mobile.svg") no-repeat;
}

.mobile-image-action-icon-edit{
    width: 24px;
    height: 19px;
    position: relative;
    top: 2px;
    margin-right: 8px;
    background: url("../images/edit_icon_mobile.svg") no-repeat;
}

.mobile-image-action-icon-view{
    width: 30px;
    height: 24px;
    position: relative;
    top: 2px;
    margin-right: 8px;
    background-color: #FFA500;
    -webkit-mask-image: url("../images/link-new.svg");
    mask-image: url("../images/link-new.svg");
}

.view-image-modal-button {
    width: 30px;
    height: 30px;
    background: url("../images/view-new.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
}

.size-warning-modal-button {
    width: 28px;
    height: 28px;
}

.eye-image-modal-button {
    width: 30px;
    height: 30px;
    background: url("../images/visibility.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
}

.error-image-icon{
    width: 40px;
    height: 40px;
    background: url("../images/image-error.png") no-repeat;
    background-size: cover;
}
.uploader-desk-title-main{
    color:  #393838;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 15px;

    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 16px;
        gap: 8px;
        padding-top: 12px;
    }
}

.drag-image{
    width: 100px;
    height: 100px;
    background: url("../images/drag-image-large.svg") no-repeat;
    background-size: cover;
    margin-bottom: 15px;
    cursor: pointer;

    @media (min-width: 769px) and (max-width: 1440px) {
        width: 80px;
        height: 80px;
        margin-bottom: 12px;
    }
}
.uploader-desk-title-main .browse {
    color:#2185D0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}
.imageUploadInputContainerLabel{
    height: 100%;
}
.uploader-desk-titles{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    position: absolute;
    height: 100%;
    top: 4px;
    left: 0;
    width: 100%;
}
.uploader-desk-titles.empty{
    justify-content: center;
}
.uploader-desk-titles.empty .uploader-desk-title-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.uploader-desk-titles.empty .uploader-desk-title-main .browse{
    color:#2185D0;
    cursor: pointer;
}
.uploader-desk-title-mb{
    display: none;
}
@media only screen and (max-width: 900px) {
    .image-uploader-item{
        min-height: 100px;
    }
    .uploader-desk-titles{
        position: static;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: #E4EBFF;
        border-radius: 8px;
    }
    .view-image-modal-button, .s3-image-button, .google-lens-button, .remove-image-button{
        visibility: hidden;
        cursor: pointer;
    }
    .uploadedImageList{
        min-height: unset;
    }
    .uploadedImageList.pd-50{
        padding:  0 25px ;
    }
    .imageUploadInputContainerLabel{
        padding: 8px;
    }
    .uploader-desk-title,.imageUploadInputContainer{
        display: none;
    }
    .drag-image{
        width: 60px;
        height: 60px;
        margin-bottom: 0;
    }

    .uploader-desk-title-mb{
        display: block;
        color: #1E3166;
        font-size: 18px;
    }
}
.uploader-desk-title{
    color: #767676;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.upload--image-text{
    position: relative;
    top: 6px;
    color: #1E3166;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
.upload--image-text-desc{
    position: relative;
    color: #2185D0;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 16px;
    }
}

.image-hover-menu{
    display: none;
    position: absolute;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    width: -webkit-calc(100% - 16px);
    width: calc(100% - 16px);
    height: -webkit-calc(100% - 16px);
    height: calc(100% - 16px);
    -webkit-transition: .35s;
    -o-transition: .35s;
    transition: .35s;
}

.bounceOut{
    animation: animationFramesOut ease .9s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    -webkit-animation: animationFramesOut ease .9s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: animationFramesOut ease .9s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -o-animation: animationFramesOut ease .9s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -ms-animation: animationFramesOut ease .9s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
}

.scale-down-center {
    -webkit-animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-down-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-down-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}
@keyframes scale-down-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
}


@keyframes animationFramesOut{
    0% {
        opacity:1;
        -webkit-transform:  translate(0px,0px)  ;
        transform:  translate(0px,0px)  ;
    }
    19% {
        opacity:1;
        -webkit-transform:  translate(20px,0px)  ;
        transform:  translate(20px,0px)  ;
    }
    20% {
        opacity:1;
        -webkit-transform:  translate(20px,0px)  ;
        transform:  translate(20px,0px)  ;
    }
    100% {
        opacity:0;
        -webkit-transform:  translate(-1000px,0px)  ;
        transform:  translate(-1000px,0px)  ;
    }
}

@-webkit-keyframes animationFramesOut {
    0% {
        opacity:1;
        -webkit-transform:  translate(0px,0px)  ;
    }
    19% {
        opacity:1;
        -webkit-transform:  translate(20px,0px)  ;
    }
    20% {
        opacity:1;
        -webkit-transform:  translate(20px,0px)  ;
    }
    100% {
        opacity:0;
        -webkit-transform:  translate(-1000px,0px)  ;
    }
}

.image-error-tooltip{
    visibility: hidden;
    width: 200px;
    background-color: rgba(0,0,0,.8);
    color: #fff;
    text-align: center;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1002;
    bottom: 105%;
    left: 50%;
    margin-left: -100px;
}

.image-error-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    zIndex: 2;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.coverShotImageItem .image-hover-menu {
    width: -webkit-calc(385px - 16px);
    width: calc(385px - 16px);
    margin-left: -2px;
    height: -webkit-calc(386px - 16px);
    height: calc(386px - 16px);
}

.image-hover-menu:hover{
    background: rgba(0,0,0,.5);
}
.image-hover-menu:hover .view-image-modal-button{
    display: block;
}
.image-hover-menu:hover .s3-image-button.s3-image-button-show{
    display: block;
}

.image-hover-menu:hover .google-lens-button.google-lens-button-show{
    display: block;
}
.image-hover-menu:hover .remove-image-button{
    display: block;
}

.uploadedImageItem:hover .image-error-tooltip, .coverShotImageItem:hover .image-error-tooltip{
    visibility: visible;
}

.uploadedImageItem:hover .image-hover-menu,.coverShotImageItem:hover .image-hover-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    z-index: 2;
}


/*modal styles*/
.image-uploader-background,.image-uploader-background-settings{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    /*z-index: 1002;*/
    z-index: 9992;
    background: rgba(0,0,0, .5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.image-uploader-background-settings{
    z-index: 117;
}

.image-uploader-modal, .uploader-settings-modal{
    min-height: calc(100vh) !important;
    min-width:  calc(100vh - 164px) !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    z-index: 99;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    max-height: -webkit-calc(100vh - 24px) !important;
    max-height: calc(100vh - 24px) !important;
    overflow: hidden !important;
}

.image-uploader-header,.uploader-settings-header{
    padding: 18px 20px;
    color: rgba(0,0,0,.85);
    height: 77px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    -webkit-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 40px;
    grid-template-rows: 40px;
    -ms-grid-columns: 90px 100px 90px;
    grid-template-columns: 90px 100px 90px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.image-uploader-header > *:nth-child(1), .uploader-settings-header > *:nth-child(1){
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.image-uploader-header > *:nth-child(2), .uploader-settings-header > *:nth-child(2){
    -ms-grid-row: 1;
    -ms-grid-column: 2;
}

.image-uploader-header > *:nth-child(3), .uploader-settings-header > *:nth-child(3){
    -ms-grid-row: 1;
    -ms-grid-column: 3;
}

.image-uploader-filename{
    margin: 0;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.image-uploader-close-icon{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 36px;
    height: 36px;
    font-size: 20px;
    cursor: pointer;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    color: #666666;
    text-rendering: auto;
}

.image-uploader-close-icon:hover {
    color: #000;
}

.image-uploader-body,.uploader-settings-body{
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0 20px 0 0;
    border: none;
}

.image-uploader-actions{
    display: flex;
    flex-wrap: wrap;
    z-index: 9;
    margin-bottom: 12px;

    @media (min-width: 769px) and (max-width: 1440px) {
        margin-bottom: 10px;
    }
}

.image-uploader-actions button{
    color: #fff !important;
}

.image-uploader-footer{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    margin-top: 12px;
    padding-left: 0 !important;
    min-height: 130px;
}

.desktop-uploader-tab-panel{
    width: 100%;
    display: none;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.uploader-modal-column:first-child{
    margin-right: 10px;
}

.canvas-background{
    overflow: hidden;
    display: -ms-grid;
    display: grid;
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.canvas-background > *:nth-child(1){
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}


.canvas-active-area-mobile{
    top: 0 !important;
}

.filters-tab-list{
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding: 0;
}
.image-uploader-sub-panel{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.image-uploader-footer{
    padding: 0 20px;

}

.image-uploader-sub-panel-desktop{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.parameters-desktop-tab-panel input[type=range]{
    position: relative;
    top: -16px;
}

.react-tabs{
    position: absolute;
    z-index: 888;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    bottom: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
}

.parameter-tab{
    margin-right: 20px;
}

.parameters-tabs{
    min-width: 140px !important;
    margin-right: 20px;
}

.filter-tab{
    margin-left: 20px;
}

.uploader-modal-column{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px;
}

.uploader-column-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

/*input[type=range] {*/
/*    -webkit-appearance: none;*/
/*    margin: 8px 0;*/
/*    width: 100%;*/

/*    -webkit-transition: .2s !important;*/

/*    -o-transition: .2s !important;*/

/*    transition: .2s !important;*/
/*}*/
/*input[type=range]:focus {*/
/*    outline: none;*/

/*    -webkit-transition: .2s !important;*/

/*    -o-transition: .2s !important;*/

/*    transition: .2s !important;*/

/*}*/
/*input[type=range]::-webkit-slider-runnable-track {*/
/*    width: 100%;*/
/*    height: 6px;*/
/*    cursor: pointer;*/
/*    background: #3bbaab;*/
/*    -webkit-transition: .2s !important;*/
/*    transition: .2s !important;*/
/*    -webkit-border-radius: 8px;*/
/*    border-radius: 8px;*/
/*}*/

/*input[type=range]:hover::-webkit-slider-thumb {*/
/*    -webkit-box-shadow: 0 0 0 6px rgb(24 144 255 / 12%);*/
/*    box-shadow: 0 0 0 6px rgb(24 144 255 / 12%);*/
/*}*/

/*input[type=range]::-webkit-slider-thumb {*/
/*    border: 1px solid #2fa99a;*/
/*    height: 16px;*/
/*    width: 16px;*/
/*    -webkit-border-radius: 50px;*/
/*    border-radius: 50px;*/
/*    background: #ffffff;*/
/*    cursor: pointer;*/
/*    -webkit-box-shadow: 0 0 0 1px #3bbaab;*/
/*    box-shadow: 0 0 0 1px #3bbaab;*/
/*    -webkit-transition: .15s box-shadow !important;*/
/*    transition: .15s box-shadow !important;*/
/*    -webkit-appearance: none;*/
/*    margin-top: -6px;*/
/*}*/
/*input[type=range]:hover::-webkit-slider-runnable-track {*/
/*    background: #2fa99a;*/
/*}*/


/*input[type=range]::-moz-range-track {*/
/*    width: 100%;*/
/*    height: 8.4px;*/
/*    cursor: pointer;*/
/*    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
/*    background: #2fa99a;*/
/*    border-radius: 1.3px;*/
/*    border: 0.2px solid #010101;*/
/*}*/
/*input[type=range]::-moz-range-thumb {*/
/*    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
/*    border: 1px solid #000000;*/
/*    height: 36px;*/
/*    width: 16px;*/
/*    border-radius: 3px;*/
/*    background: #ffffff;*/
/*    cursor: pointer;*/
/*}*/
/*input[type=range]::-ms-track {*/
/*    width: 100%;*/
/*    height: 8.4px;*/
/*    cursor: pointer;*/
/*    background: transparent;*/
/*    border-color: transparent;*/
/*    border-width: 16px 0;*/
/*    color: transparent;*/
/*}*/
/*input[type=range]::-ms-fill-lower {*/
/*    background: #3bbaab;*/
/*    border: 0.2px solid #010101;*/
/*    border-radius: 2.6px;*/
/*    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;*/
/*}*/

.image-filter-item{
    width: 100px;
    height: 100px;
    padding: 0 20px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    background-position: center;
    cursor: pointer;
}

#image-filters{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    width: 100%;
}

.image-filter-container{
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px);
    margin-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.image-filter-list{
    display: -webkit-box;
    margin-left: 14px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.image-filter-list::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

/* Track */
.image-filter-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px grey;
    box-shadow: inset 0 0 4px grey;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

/* Handle */
.image-filter-list::-webkit-scrollbar-thumb {
    background: #666;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

/* Handle on hover */
.image-filter-list::-webkit-scrollbar-thumb:hover {
    background: #666;
}

.image-filter-list > div{
    height: 100px;
    width: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.image-filter-list > div span, .image-filter-default > div span{
    position: relative;
    font-size: 12px;
    -webkit-filter:  grayscale(0%) !important;
    filter:  grayscale(0%) !important;
    z-index: 260;
    width: 60px;
    text-align: center;
    top: 18px;
}

.image-filter-default > div{
    height: 100px;
    width: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.image-filter-list > div:first-child,  .image-filter-list > div:first-child::before{
    -webkit-border-radius: 8px 0 0 8px;
    border-radius: 8px 0 0 8px;
}

.image-filter-list > div:last-child, .image-filter-list > div:last-child::before {
    -webkit-border-radius: 0 8px 8px 0;
    border-radius: 0 8px 8px 0;
}

.preview-1977{
    position: relative;
    -webkit-filter: contrast(110%) brightness(110%) saturate(130%);
    filter: contrast(110%) brightness(110%) saturate(130%);

}

.preview-1977::before{
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: screen;
    background: rgba(243, 106, 188, 0.3);
}

.preview-aden{
    position: relative;
    -webkit-filter: contrast(90%) brightness(150%) saturate(85%) hue-rotate(20deg);
    filter: contrast(90%) brightness(150%) saturate(85%) hue-rotate(20deg);
}
.preview-aden::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: darken;
    background: -o-linear-gradient(left, rgba(66, 10, 14, 0.2) 1, rgba(66, 10, 14, 0));
    background: -webkit-gradient(linear, left top, right top, color-stop(1, rgba(66, 10, 14, 0.2)), to(rgba(66, 10, 14, 0)));
    background: linear-gradient(to right, rgba(66, 10, 14, 0.2) 1, rgba(66, 10, 14, 0));
}

.preview-amaro {
    position: relative;
    -webkit-filter: contrast(90%) brightness(112%) saturate(150%) hue-rotate(-10deg);
    filter: contrast(90%) brightness(112%) saturate(150%) hue-rotate(-10deg);
}

.preview-brannan {
    position: relative;
    -webkit-filter: contrast(140%) sepia(50%);
    filter: contrast(140%) sepia(50%);
}

.preview-brannan::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: lighten;
    background: rgba(161, 44, 199, 0.31);
}

.preview-brooklyn {
    position: relative;
    -webkit-filter: contrast(90%) brightness(110%);
    filter: contrast(90%) brightness(110%);
}
.preview-brooklyn::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: overlay;
    background: rgba(168, 223, 193, 0.3);
}

.preview-clarendon {
    position: relative;
    -webkit-filter: contrast(120%) saturate(125%);
    filter: contrast(120%) saturate(125%);
}
.preview-clarendon::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: overlay;
    background: rgba(127, 187, 227, 0.2);
}

.preview-earlybird {
    position: relative;
    -webkit-filter: contrast(90%) sepia(20%);
    filter: contrast(90%) sepia(20%);
}
.preview-earlybird::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: overlay;
    background: rgba(208, 186, 142, 1);
}

.preview-inkwell {
    position: relative;
    -webkit-filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);
    filter: contrast(110%) brightness(110%) sepia(30%) grayscale(100%);
}
.preview-inkwell::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
}

.preview-lofi {
    position: relative;
    -webkit-filter: contrast(150%) saturate(110%);
    filter: contrast(150%) saturate(110%);
}
.preview-lofi::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: multiply;
    background: rgba(0, 0, 0, 0);
}

.preview-maven {
    position: relative;
    -webkit-filter: contrast(95%) brightness(95%) saturate(150%) sepia(25%);
    filter: contrast(95%) brightness(95%) saturate(150%) sepia(25%);
}
.preview-maven::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: hue;
    background: rgba(3, 230, 26, 0.2);
}

.preview-perpetua {
    position: relative;
}
.preview-perpetua::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: soft-light;
    opacity: 0.5;
    background: rgba(0, 91, 154, 1);
}

.preview-hudson {
    position: relative;
    -webkit-filter:  contrast(90%) brightness(120%) saturate(110%);
    filter:  contrast(90%) brightness(120%) saturate(110%);
}
.preview-hudson::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: multiply;
    opacity: 0.5;
    background: rgba(255, 177, 166, 0.5);
}

.preview-gingham {
    position: relative;
    -webkit-filter: brightness(105%) hue-rotate(350deg);
    filter: brightness(105%) hue-rotate(350deg);
}
.preview-gingham::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: darken;
    background: rgba(66, 10, 14, 0.2);
}


.preview-reyes {
    position: relative;
    -webkit-filter: contrast(85%) brightness(110%) saturate(75%) sepia(22%);
    filter: contrast(85%) brightness(110%) saturate(75%) sepia(22%);
}
.preview-reyes::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: soft-light;
    opacity: 0.5;
    background: rgba(173, 205, 239, 1);
}

.preview-stinson {
    position: relative;
    -webkit-filter: contrast(75%) brightness(115%) saturate(85%);
    filter: contrast(75%) brightness(115%) saturate(85%);
}
.preview-stinson::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: soft-light;
    background: rgba(240, 149, 128, 0.2);
}

.preview-toaster {
    position: relative;
    -webkit-filter: contrast(150%) brightness(90%);
    filter: contrast(150%) brightness(90%);
}
.preview-toaster::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: screen;
    opacity: 0.5;
    background:  rgba(15, 78, 128, 1);
}

.preview-walden {
    position: relative;
    -webkit-filter: brightness(110%) saturate(160%) sepia(30%) hue-rotate(350deg);
    filter: brightness(110%) saturate(160%) sepia(30%) hue-rotate(350deg);
}
.preview-walden::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: screen;
    opacity: 0.3;
    background: rgba(204, 68, 0, 1);
}

.preview-valencia {
    position: relative;
    -webkit-filter: contrast(108%) brightness(108%) sepia(8%);
    filter: contrast(108%) brightness(108%) sepia(8%);
}
.preview-valencia::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: exclusion;
    opacity: 0.5;
    background: rgba(58, 3, 57, 1);
}

.preview-xpro2{
    position: relative;
    -webkit-filter: sepia(30%);
    filter: sepia(30%);
}
.preview-xpro2::before {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
    mix-blend-mode: color-burn;
    background:  rgba(224, 231, 230, 1);
}

input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
}
input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
}

.image-uploader-modal, .uploader-settings-modal,.image-uploader-header, .uploader-settings-header{
    -webkit-border-radius: 8px !important;
    border-radius: 8px !important;
}

.uploader-tabs-container{
    width: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.uploader-tabs-container ul{
    list-style: none;
    color: #000;
    font-size: 12px;
}
.filters-web-tab-list{
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.parameters-web-tab,.filters-web-tab{
    height: 76px;
    width: 80px;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.filters-web-tab{
    margin-top: 4px;
}

.image-uploader-body{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}


.parameters-image-icon,.filters-image-icon,.photoroom-image-icon{
    width: 40px !important;
    min-height: 40px !important;
    background-position: center !important;
    background-size: 40px !important;
    background-color: #fff;
    margin-bottom: 4px;
}

.parameters-image-icon{
    background: url("../images/filter.svg") no-repeat;
}

.filters-image-icon{
    background: url("../images/rgb.svg") no-repeat;
}

.filters-web-tab-list span{
    font-size: 15px;
}

.photoroom-dark-blue-image-icon{
    background: url(../images/eraser_dark_blue.svg) no-repeat !important;
    margin-bottom: 0;
    background-size: 18px !important;
    min-height: 14px !important;
    width: 20px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        background-size: 14px !important;
        min-height: 11px !important;
        width: 16px !important;
    }
}

.bulk-restore-button-icon{
    background: url(../images/restore.svg) no-repeat !important;
    margin-bottom: 0;
    background-size: 15px !important;
    min-height: 16px !important;
    width: 20px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        min-height: 13px !important;
        width: 16px !important;
    }
}


.photoroom-banner{
    background: url("../images/photoroom_banner.svg") no-repeat;
    width: 140px;
    height: 40px;
    margin-top: 10px;
    background-size: cover;
}

.photoroom-banner-bulk-actions{
    height: 23px !important;
    background-size: 95px !important;
}

.photoroom-logo-first-part{
    width: 26px;
    background-position: left;
}

.photoroom-logo-second-part{
    width: 68px;
    background-position: right;
}

.listperfectly-dropdown-content-item:not(.disabled){
    cursor: pointer;
}

#canvasForGenerateImage{
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    width: auto;
    margin-left: 10px;
    height: auto;
}

@media only screen and (max-width: 600px) {
    .image-uploader-background,.image-uploader-background-settings {
        background: rgba(0,0,0, 0.9) !important;
    }

}

.image-uploader-modal-mobile{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
    left: 0;
}

.overflow-hidden{
    overflow: hidden !important;
}

.filter-tab, .parameter-tab{
    width: 60px;
    height: 60px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background: #d9d9d9;
    -webkit-transition: all ease-in-out 1ms !important;
    -o-transition: all ease-in-out 1ms !important;
    transition: all ease-in-out 1ms !important;
}

.filter-tab--selected svg{
    -webkit-transition: all ease-in-out 1ms !important;
    -o-transition: all ease-in-out 1ms !important;
    transition: all ease-in-out 1ms !important;
}

.parameters-tabs-list{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    min-width: 24em !important;
    list-style: none;
    font-size: 24px;
    color: #fff;
    height: 1.5em;
    min-height: 50px;
    overflow-x: scroll;
    margin: auto;
    --range-input-feather-left: 2em;
    --range-input-feather-right: 2em;
    --range-input-mask-from: 0%;
    --range-input-mask-to: 100%;
    --range-input-mask-color: rgba(0,0,0,0.25);
    --range-input-line-color: var(--color-foreground-15);
    outline: transparent;
}

.parameters-tabs--selected,.parameters-desktop-tabs--selected{
    -webkit-backdrop-filter: rgba(255, 255, 255, 0.2);
    backdrop-filter: rgba(255, 255, 255, 0.2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #E5E5E5;
    -webkit-box-shadow: inset 0 0 0 1px rgba(255 ,255, 255, 0.08);
    box-shadow: inset 0 0 0 1px rgba(255 ,255, 255, 0.08);
}
.parameters-tab-panel{
    position: relative;
    bottom: 20px;
}



.parameters-tabs,.parameters-desktop-tabs{
    height: 40px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    padding: 0 16px;
}

.parameters-desktop-tabs{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid #fff !important;
    -webkit-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    cursor: pointer;
}

.filter-tab-panel--selected{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.parameters-desktop-tabs:hover{
    border: 1px solid rgba(0,0,0, 0.15) !important;
}

.parameters-desktop-tabs--selected{
    background-color: #E5E5E5;
}

.parameters-tabs-list,.parameters-desktop-tabs-list{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 6px;
}

.parameters-desktop-tabs-list{
    width: 100%;
    margin: 0 !important;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
}

.remove-background-stats-container{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    left: 6px;
}

.filter-tab--selected{
    background: #ebebeb;
    -webkit-border-radius: 8px !important;
    border-radius: 8px !important;
}

.done_button{
    width: 45px;
    background: #3abaab;
    height: 45px;
    position: relative;
    top: 14px;
    right: 10px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    z-index: 190;
}

.history_button{
    width: 45px;
    height: 45px;
    left: 10px;
    position: relative;
    top: 14px;
    z-index: 190;
}
.top-mobile-header{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.image-uploader-body-mobile{
    position: absolute !important;
    top: 0 !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
}

#image-uploader-body-item-mobile{
    top: 0;
    position: absolute;
}

.history-container{
    -webkit-border-radius: 50px;
    border-radius: 50px;
    cursor: pointer;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.next-step-image-icon, .prev-step-image-icon{
    width: 40px;
    -webkit-border-radius: 0 20px 20px 0;
    border-radius: 0 20px 20px 0;
    position: relative;
    height: 100%;
    background: url("../images/undo.svg") no-repeat;
    background-size: 18px;
    background-position: center;
    cursor: pointer;
}

.rotate-left-icon,.icon,.rotate-right-icon{
    width: 40px;
    -webkit-border-radius: 0 20px 20px 0;
    border-radius: 0 20px 20px 0;
    position: relative;
    height: 100%;
    background-size: 18px !important;
    background-position: center !important;
    cursor: pointer;
    min-height: 40px;
}

.parameters-tab-panel-rotate-right, .parameters-tab-panel-rotate-left{
    cursor: pointer;
}
.parameters-tab-panel-rotate-right > div,.parameters-tab-panel-rotate-left > div{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding: 0 8px;
    width: 180px;
}

.parameters-tab-panel-rotate-right > div{
    margin-left: 12px;
}

.parameters-tab-panel-rotate-left > div{
    margin-right: 12px;
}

.rotate-input{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.rotate-value{
    position: absolute;
    color: #666;
}
.rotate-tab{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    min-width: 450px;
}

.rotate-left-icon{
    background: url("../images/turn-left.svg") no-repeat;
}

.rotate-right-icon{
    background: url("../images/turn-right.svg") no-repeat;
}

.flip-vertical-icon{
    background: url("../images/vertical_flip.svg") no-repeat;
}

.flip-horizontal-icon{
    background: url("../images/horizontal_flip.svg") no-repeat;
}

.next-step-image-icon:hover, .prev-step-image-icon:hover{
    background-color: #ebebeb;
}

.prev-step-image-icon{
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
    padding-right: 6px;
    height: 100%;
    border: 1px solid #3abaab;
}



.next-step-image-icon{
    height: 100%;
    border: 1px solid #3abaab;
    border-left: none;
}

.edit-steps-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    width: 90px;
}

.upload-submit-btn,.dark-blue-btn {
    width: 100px !important;
    z-index: 999 !important;
    background-color: #234165 !important;
    -webkit-border-radius: 8px !important;
    border-radius: 8px !important;
    height: 39px !important;
    font-size: 16px !important;
    text-align: center !important;
    border: none !important;
    color: #fff !important;
    cursor: pointer !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex  !important;
    display: flex  !important;
    -webkit-box-pack: center  !important;
    -ms-flex-pack: center  !important;
    -webkit-justify-content: center  !important;
    justify-content: center  !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    -webkit-align-items: center !important;
    align-items: center !important;
}

.upload-submit-btn:hover,.dark-blue-btn:hover{
    background-color: #2e5893 !important;
}

.uploader-header-left-side{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.filters-web-tab-list{
    font-size: 12px !important;
}

#react_image_uploader_loader_container {
    position: relative;
    z-index: 99999;
    min-height: 240px;
    width: 100%;
    background: rgba(82, 183, 158, 0.12);
    top: 0;
    left: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.lazy-background {
    position: relative;
    transition: background-image 0.3s ease-out;
}

.skeleton-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e0e0e0 linear-gradient(
            90deg,
            #e0e0e0 0%,
            #f5f5f5 50%,
            #e0e0e0 100%
    );
    background-size: 200% 100%;
    animation: shimmer 3s infinite linear;
    z-index: 1;
}

@keyframes shimmer {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.lazy-background.loading .skeleton-loader {
    display: none; /* Hide skeleton once thumbnail is loaded */
}

.lazy-background.loaded {
    filter: blur(0);
}



#react_image_uploader_modal_loader_container{
    position: absolute;
    z-index: 9999;
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

}

.react_image_uploader_modal_loader{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    background: #fff;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.photoroom-image-icon {
    background: url(../images/eraser.svg) no-repeat;
}

.react_image_modal_uploader_loader{
    width: 170px;
    height: 170px;
}

.react_image_uploader_modal_loader {
    color: #234165 !important;
    white-space: pre;
}

#react_image_uploader_loader_container .local-loading-text{
    white-space: pre;
}

.image-uploader-body-loader{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: -webkit-calc(100vh - 77px - 24px);
    height: calc(100vh - 77px - 24px);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

#react_image_uploader_loader_container span {
    padding-top: 10px;
    color: #234165;
    display: flex;
    text-align: center;
}

.react_image_uploader_loader {
    width: 100px !important;
    height: 100px !important;
}

.react_lambda_uploader_loader {
    width: 100% !important;
    height: 100% !important;
    background: url("../images/labda_loader.svg") no-repeat;
    background-size: 80px;
    background-position: center;
}

.video_item_preview{
    width: 100% !important;
    height: 100% !important;
}

.video_item_preview_modal{
    width: 100% !important;
    height: 100% !important;
    padding: 20px;
}

.react-draggable{
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.ReactCrop .ord-n, .ReactCrop .ord-e, .ReactCrop .ord-s, .ReactCrop .ord-w {
    display: block;
}

.full-size{
    width: 100%;
    height: 100%;
}

.image-drop-active{
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
    transition: all 100ms;
    border: gray dashed 2px;
}

.image-drop-active * {
    pointer-events: none;
}

.disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: transparent;
}

.react-uploader-switch *{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: transparent;
}

.remove-background-stats-container .custom-tooltip-big{
    left: 6px;
}


.desktop-uploader-tab-panel{
    height: 152px;
}

.uploader-body-canvas{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.uploader-body-canvas:not(.show-crop-grid) .ReactCrop__rule-of-thirds-hz,.uploader-body-canvas:not(.show-crop-grid) .ReactCrop__rule-of-thirds-vt{
    display: none !important;
}

.react-uploader-switch-container span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1E3166;
    text-transform: capitalize;
}

li.filter-web-tab,li.parameter-web-tab{
    padding: 4px !important;
}

.ReactCrop__drag-handle{
    width: 14px !important;
    background: #45d2b1;
    height: 14px !important;
    z-index: 99 !important;
}

.ReactCrop .ord-e{
    right: -3px !important;
}

.ReactCrop .ord-n{
    top: -3px !important;
}

.ReactCrop .ord-w{
    left: -3px !important;
}

@media only screen and (max-width: 900px) {
    .react-uploader-switch-container span {
        font-size: 14px;
    }

    .iu-color-picker{
        left: -220px;
    }

    .large-12.columns{
        padding: 0 !important;
    }

    #image-uploader>.LPE_sizeLy{
        top: -132px !important;
    }

    .image-uploader-actions-item{
        z-index: 999;
        position: absolute;
    }

    .image-uploader-actions{
        min-height: 39px;
    }


    .ReactCrop__drag-handle {
        width: 32px !important;
        height: 32px !important;
    }
    .ReactCrop .ord-ne{
        right: 16px;
        top: 16px;
    }
    .ReactCrop .ord-e{
        right: 16px !important;
    }
    .ReactCrop .ord-se{
        bottom: 16px;
        right: 16px;
    }
    .ReactCrop .ord-nw{
        top: 16px;
        left: 16px;
    }
    .ReactCrop .ord-w{
        left: 16px !important;
    }
    .ReactCrop .ord-sw{
        bottom: 16px !important;
        left: 16px !important;
    }
    .ReactCrop .ord-n{
        top: 16px !important;
    }
    .ReactCrop .ord-s{
        bottom: 16px !important;
    }

    .custom-tooltip:hover::after {
        display: none !important;
    }

    .image-uploader-footer .react-uploader-switch-container{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .image-uploader-background,.image-uploader-background-settings {
        background: rgba(0,0,0, 0.9) !important;
        position: fixed;
        z-index: 9999;
        width:100%;
        height: 100%;
        top: 0;
        left: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .remove-background-stats-container .custom-tooltip-big{
        display: none !important;
    }

    .image-uploader-body{
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        padding: 0;
    }

    .canvas-background{
        top: unset;
        right: unset;
    }
    .desktop-uploader-tab-panel,#image-filters{
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        padding: 0;
    }

    .image-uploader-sub-panel-desktop{
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        width: 100%;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }
    .desktop-uploader-tab-panel{
        height: 144px;
    }

    .image-uploader-footer{
        padding: 0;
        width: 100%;
        border-top: 1px solid #f0f0f0;
        margin-top: 0;
        max-height: 205px !important;
        min-height: 205px;
    }

    .filters-web-tab-list{
        margin-bottom: -2px;
    }

    .parameters-web-tab, .filters-web-tab{
        -webkit-border-radius: 100px !important;
        border-radius: 100px !important;
        height: 100%;
        width:  100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .filters-image-icon,.parameters-image-icon{
        background-size: 28px !important;
        margin: 0 !important;
    }


    .rotate-tab{
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        min-width: 320px;
    }

    .image-filter-default,.image-filter-item{
        z-index: 1001;
    }

    .image-filter-container{
        height: 100px;
    }

    .modal-body-btn{

    }


    .parameters-tab-panel-rotate-left,.parameters-tab-panel-rotate-right{
        position: relative;
        top: -8px;
    }

    .uploader-tabs-container{
        position: fixed;
        bottom: 2px;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;

    }

    .image-filter-container{
        margin-top: 7px !important;
    }

    li.filter-web-tab,li.parameter-web-tab{
        padding: 4px !important;
        height: 80px;
        width: 80px;
        margin-bottom: 4px;
    }

    .parameters-desktop-tabs{
        min-width: 90px;
        margin: 0 4px;
        width: 90px;
        height: 30px;
        font-size: 15px;
    }

    li.filter-web-tab{
        margin-left: 10px;
    }
    .filters-web-tab-list{
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
    }

    .parameters-desktop-tabs-list{
        margin-top: 0px !important;
        margin-bottom: 42px !important;
    }

    .parameters-tab-panel-rotate-left > div{
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        -webkit-justify-content: flex-start !important;
        justify-content: flex-start !important;
    }

    .parameters-tab-panel-rotate-right > div {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        -webkit-justify-content: flex-end !important;
        justify-content: flex-end !important;
    }

    .hide-in-mobile{
        display: none !important;
    }

    .image-uploader-actions{
        display: grid;
        align-items: flex-start;
        justify-items: flex-start;
        position: static;
        grid-template-columns: 1fr;
        margin-bottom: 12px;
    }

    .react-uploader-switch-container{
        margin-left: 20px !important;
        margin-bottom: 4px;
    }

    .image-uploader-actions.selection-opened{
        grid-template-columns: 1.4fr 1fr 1fr;
        justify-items: normal;
        grid-column-gap: 8px;
    }

    .listperfectly-checkbox label{
        padding: 15px 35px 15px 15px !important;
    }

    .listperfectly-checkbox label,.listperfectly-btn,.listperfectly-btn-icon{
        font-size: 11px !important;
        margin-left: 0 !important;
        margin-bottom: 0 !important;
    }

    .listperfectly-btn{
        padding: 8px 12px !important;
    }

    .coverShotImageItem,.coverShotImageItem .image-hover-menu{
        width:  100%;
        margin-left: 0;
        height: 100%;
    }

    .coverShotImageItem:after,.coverShotImageItem .image-hover-menu:after  {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .coverShotImageItem .image-hover-menu{
        top: -1px;
        left: 0;
        -webkit-border-radius: 6px;
        border-radius: 6px;
    }

    .image-uploader-container{
        padding: 0px !important;
    }

    .uploadedImageList{
        -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(var(--repeat, auto-fit), -webkit-calc(50% - 4px));
        grid-template-columns: repeat(var(--repeat, auto-fit), calc(50% - 4px));
    }

    .uploadedImageItem,.imageUploaderContainer {
        padding: 8px;
        -webkit-border-radius: 8px;
        border-radius: 8px;
        height: 100%;
        border: 1px solid #d9d9d9;
        width: 100%;

    }
    .imageUploaderContainer{
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        min-height: 120px;
        min-width: 120px;
        max-width: 320px;
        max-height: 320px;
    }
    .uploadedImageItem .uploadedImagePreview:after{
        content: "";
        display: block;
        padding-bottom: 100%;
    }


    .uploader-header-left-side{
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }


    .image-uploader-header{
        -webkit-border-radius: 0 !important;
        border-radius: 0 !important;
        padding: 8px 20px;
        height: 50px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        max-height: 50px !important;
        align-items: center !important;
    }

    .image-uploader-footer{
        -webkit-border-radius: 0 !important;
        border-radius: 0 !important;
    }

    .upload-submit-btn,.dark-blue-btn{
        height: 25px !important;
        width: 70px !important;
        font-size: 14px !important;
        margin: 0;
        padding: 0;
    }

    .image-uploader-header{
        -ms-grid-columns: 60px 70px 60px !important;
        grid-template-columns: 60px 70px 60px !important;
        width: 100vw !important;
        padding: 0 !important;
        height: 40px !important;
        margin: 0 !important;
    }

    .modal-header-right{
        margin-right: 12px;
    }

    .modal-header-left{
        margin-left: 12px;
    }

    .history-image-icon, .close-image-icon,.minimize-modal-window {
        width: 23px !important;
        height: 23px !important;
        top: 1px !important;
    }

    .close-image-icon,.minimize-modal-window {
        background-size: 18px !important;
        top: 0 !important;
    }

    .minimize-modal-window{
        position: relative;
        right: 8px !important;
    }

    .image-filter-item {
        z-index: 999 !important;
        cursor: pointer !important;
        padding: 0 !important;
    }

    .rotate-left-icon, .icon, .rotate-right-icon {
        width: 30px;
        min-height: 32px !important;
        height: 30px;
    }

    .parameters-tab-panel-rotate-right > div, .parameters-tab-panel-rotate-left > div{
        font-size: 14px;
    }

    .confirm-crop-btn{
        position: relative;
        top: 6px;
        min-width: 160px !important;
    }

    .image-uploader-item {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }

    .image-uploader-modal{
        -webkit-border-radius: 0 !important;
        border-radius: 0 !important;
        max-height: unset !important;
        min-width: 100% !important;
        min-height: 100% !important;
        max-width: unset !important;
    }

    .image-filter-list > div {
        height: 80px;
        width: 60px;
    }

    .image-filter-default > div {
        height: 80px;
        width: 60px;
    }

    .remove-background-tab .announce-link,.remove-background-tab .announce-message{
        position: absolute !important;
        top: 1px !important;
        font-size: 12px !important;
        line-height: 14px !important;
    }

    .remove-background-tab .photoroom-banner{
        width: 116px;
        height: 30px;
        position: relative;
        margin-top: 0;
        top: 3px;
        background-size: cover;
    }
}

.image-uploader-container .ReactCrop *{
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.image-uploader-container + .LPE_sizeLy{
    top: 110px !important;
}

.rangeslider-horizontal .rangeslider__fill {
    background-color: #4db89e;
}

.rangeslider-horizontal {
    margin: 34px 0;
}


/*crop customization*/
.ReactCrop__crop-selection{
    border: 3px solid #4db89e !important;
    border-image-slice: 1 !important;
    border-image-repeat: repeat !important;
}






.show-crop-grid .ReactCrop__rule-of-thirds-hz::before{
    top: -webkit-calc(100% / 3);
    top: calc(100% / 3);
}

.show-crop-grid .ReactCrop__rule-of-thirds-hz::after{
    top: -webkit-calc(100% / 3 * 2);
    top: calc(100% / 3 * 2);
}

.show-crop-grid .ReactCrop__rule-of-thirds-vt::before{
    left: -webkit-calc(100% / 3);
    left: calc(100% / 3);
}

.show-crop-grid .ReactCrop__rule-of-thirds-vt::after{
    left: -webkit-calc(100% / 3 * 2);
    left: calc(100% / 3 * 2);
}

.show-crop-grid .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
    width: 100%;
    height: 1px;
}

.show-crop-grid .ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
    width: 1px;
    height: 100%;
}

.show-crop-grid .ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
}

.minimize-modal-window{
    display: none;
}


.react-uploader-switch {
    position: relative;
    display: inline-block;
    width: 69px;
    height: 39px;
    margin-left: 13px;
    margin-bottom: 0;
}

.react-uploader-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.react-uploader-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E5E5E5;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.react-uploader-slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 5px;
    bottom: 7px;
    background-color: #4eb99e;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

input:not(:checked) + .react-uploader-slider:before {
    background: #fff;
    left: 11px;
}

input:not(:checked) + .react-uploader-slider:before {
    background: #fff;
    left: 11px;
}


input:checked + .react-uploader-slider {
    border: 1px solid #4eb99e;
    background: rgba(0,0,0,0);
    border-radius: 100px;
}

.react-uploader-switch-container.disabled input + .react-uploader-slider{
    background: #E5E5E5 !important;
    border: 1px solid  #E5E5E5 !important;
}

.react-uploader-switch-container.disabled *{
    pointer-events: none !important;

}

.react-uploader-switch-container.disabled input + .react-uploader-slider:before{
    background: #fff !important;
}

input:focus + .react-uploader-slider {
    -webkit-box-shadow: 0 0 1px #4db89e;
    box-shadow: 0 0 1px #4db89e;
}

input:checked + .react-uploader-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.react-uploader-slider.react-uploader-round {
    /*-webkit-border-radius: 34px;*/
    /*border-radius: 34px;*/
    border-radius:100px;
}

.react-uploader-slider.react-uploader-round:before {
    -webkit-border-radius: 16px;
}

.uploader-announces-header{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    min-height: 22px;
    justify-content: space-between;
    align-items: flex-end !important;
}

.uploader-announces-header .react-uploader-switch-container{
    display: none !important;
    visibility: hidden !important;
}

.react-uploader-switch-container{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.parameters-tab-panel-crop{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    -webkit-justify-content: space-between !important;
    justify-content: space-between !important;
    height: 80px !important;
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.confirm-crop-button{
    margin: 0 30px;
    min-width: 214px;
    padding: 8px !important;
    background: #4db89e !important;
}

.modal-body-btn{
    margin: 0 30px;
    min-width: 214px;
    background: #4db89e !important;
}

.modal-body-btn:hover{
    background: #45a38d !important;
}

#image-uploader,.old_image_uploader,.uploader-announces-header{
    display: none;
}

.rangeslider-horizontal .rangeslider__handle{
    outline: none !important;
}

.parameters-tab-panel-crop div:nth-child(2){
    margin-left: 14px;
}

/*toast styles*/
.jq-toast-single{
    min-height: 40px;
    font-size: 13px;
    word-break: break-word;
    background: #fff;
    text-align: left;
    display: block;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin: auto;
    border-left: 10px solid #FF0003;
    -webkit-box-shadow: rgb(101 101 101) 0 0 10px -5px;
    box-shadow: rgb(101 101 101) 0 0 10px -5px;
    color: #234165;
}

.toast-text-container{
    padding-right: 12px;
}

.toast-text-container{
    padding-right: 16px;
}

.close-jq-toast-single{
    top: 5px;
    right: 7px;
    font-size: 24px;
    cursor: pointer;
}

.toast-action-button{
    font-size: 12px;
    margin-top: 6px;
    padding: 8px !important;
    background: #4eb99e !important;
    width: 60px !important;
    z-index: 999 !important;
    -webkit-border-radius: 4px !important;
    border-radius: 4px !important;
    height: 18px !important;
    text-align: center !important;
    border: none !important;
    color: #fff !important;
    cursor: pointer !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    -webkit-align-items: center !important;
    align-items: center !important;
}

.jq-toast-wrap .jq-toast-single:not(:first-child){
    margin-top: 8px;
}

.confirm-remove-background:disabled, .confirm-crop-btn:disabled {
    background-color: #cccccc !important;
    color: #666666 !important;
    cursor: default !important;
}
.ReactCrop.original-preview,.image-uploader-modal ,.canvas-background{
    overflow: visible !important;
}

.ReactCrop__crop-selection{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}


.size-warning-modal-button{
    display: none !important;
}

.uploader-settings-header{
    height: 60px;
    padding: 12px 14px;
}

.uploader-settings-header > div{
    display: flex;
    justify-content: center;
    align-items: center;
}


.uploader-settings-header > div:nth-child(3){
    display: flex;
    justify-content: right;
    align-items: center;
}

.uploader-settings-header > div span{
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: right;
    align-items: center;
}


.uploader-settings-body{
    min-height: 160px;
    padding: 16px 14px;
}

.uploader-settings-body > div{
    display: flex;
    justify-content: flex-start;
}

.uploader-settings-body > div > span{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.image-uploader-background-settings.uploader-settings-modal-container{
    display: none;
}

.listperfectly-btn{
    height: 39px;
    background: #1E3166;
    border-radius: 8px;
    padding: 15px 23px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    text-transform: capitalize;
    align-items: center;
}

.listperfectly-checkbox{
    height: 39px;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    box-shadow: none;
    position: relative;
    outline: none;
    font-weight: 500;
    display: flex;
    justify-content: center;
    text-transform: capitalize !important;
    align-items: center;
    border: 1px solid #1E3166;
    border-radius: 8px;
}

.listperfectly-checkbox label{
    color: #1E3166;
    cursor: pointer;
    padding: 15px 45px 15px 15px;
    z-index: 99;
    font-size: 16px;
    margin-bottom: 0;
    text-transform: capitalize !important;
    line-height: 19px;
}
.listperfectly-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.listperfectly-checkbox .listperfectly-checkbox-checkmark{
    height: 23px;
    width: 23px;
    border-radius: 4px;
    position: absolute;
    right: 10px;
    border: 1px solid #1E3166;
}

.listperfectly-checkbox input:checked ~ .listperfectly-checkbox-checkmark {
    background: #1E3166 url("../images/checkmark.svg") no-repeat center;
    background-size: 16px;
}

.listperfectly-btn:disabled,.listperfectly-btn:disabled:hover, .listperfectly-btn:disabled:focus{
    background: #B6B6B6 !important;
    cursor: default;
    opacity: 1 !important;
}

.listperfectly-btn:hover,.listperfectly-btn:focus{
    background: #283c71 !important;
}
.listperfectly-btn-icon:hover,.listperfectly-btn-icon:focus{
    background: rgba(0, 0, 0, 0) !important;
}

ul > #field_1_673.gfield{
    margin-top: 0 !important;
}

label.gfield_label[for=input_1_728]{
    margin-top: 14px;
}

.listperfectly-btn-icon{
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0);
    border: none;
    box-shadow: none;
    cursor: pointer;
    margin-bottom: 0 !important;
    padding: 0 14px 0 14px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        height:29px;
        padding: 0 11px 0 11px !important;
    }
}

.listperfectly-btn-icon span{
    color: #1E3166;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 11px;
        line-height: 13px;
    }
}

.listperfectly-btn-icon:disabled span,.listperfectly-btn-icon:disabled:hover span, .listperfectly-btn-icon:disabled:focus span{
    color: #B6B6B6 !important;
}

.listperfectly-dropdown-button{
    height: 39px;
    border: 1px solid #1E3166;
    border-radius: 8px;
    padding: 9px 14px 0 14px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: none;
    background: #fff;
    color: #1E3166;
    outline: none;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    align-items: center;
    min-width: 200px;

    @media (min-width: 769px) and (max-width: 1440px) {
        height: 32px;
        padding: 7px 11px 0 11px;
        min-width: 160px;
    }
}

.listperfectly-dropdown-button.opened{
    border-bottom: none !important;
    height: 100%;
    border-radius: 8px 8px 0 0 !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 6px 6px 0 0 !important;
    }
}

.listperfectly-dropdown-button .btn-icon-container span{
    color: #1E3166;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 11px;
    }
}

.dropdown-icon{
    background: url("../images/dropdown-icon.svg") no-repeat;
    background-size:12px !important;
    background-position: center;
    min-width: 20px;
    transition: 0.3s all ease-in-out !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        background-size: 10px !important;
        min-width: 16px;
    }
}

.opened .dropdown-icon{
    transform: rotateX(180deg);
}

.btn-icon-container{
    display: flex;
}

.bulk-icon{
    background: url("../images/bulk_actions.svg") no-repeat;
    background-size:25px !important;
    background-position: left;
    min-width: 32px;
    min-height: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
        background-size: 20px !important;
        min-width: 26px;
        min-height: 16px;
    }
}

.image-uploader-actions-item{
    display: flex;
    flex-direction: column;
    min-height: 46px;
    margin-bottom: -10px;
}

.listperfectly-dropdown-content{
    border: 1px solid #1E3166;
    border-top: none;
    background: #fff;
    /*z-index: 9999;*/
    z-index: 999;
    border-radius: 0 0 8px 8px !important;
    padding: 9px 0 9px 0;
    display: none;

    @media (min-width: 769px) and (max-width: 1440px) {
        padding: 7px 0 7px 0;
    }
}

.listperfectly-dropdown-content.opened{
    display: block;
    position: absolute;
    top: 46px;
    min-width: 200px;
}
@media (min-width: 769px) and (max-width: 1440px) {
    .listperfectly-dropdown-content.opened {
        min-width: 160px;
    }
}

.image-uploader-actions-item-btn-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-bottom: 9px;

    @media (min-width: 769px) and (max-width: 1440px) {
        padding-bottom: 7px;
    }
}

.opened .image-uploader-actions-item-btn-container{
    padding-bottom: 21px;
    border-bottom: 1px solid #1E3166;
}

.listperfectly-dropdown-content-item .bulk-action-icon{
    margin-right: 7px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        margin-right: 6px !important;
    }
}

.listperfectly-dropdown-content-item:hover{
    background: #1E3166 !important;
}

.listperfectly-dropdown-content-item:hover span{
    color: #fff !important;
}

.listperfectly-dropdown-content-item:hover .photoroom-dark-blue-image-icon{
    background: url(../images/eraser_actions.svg) no-repeat !important;
    background-size: 18px !important;
}

.listperfectly-dropdown-content-item:hover .bulk-restore-button-icon{
    background: url(../images/restore_disabled_hover.svg) no-repeat !important;
    background-size: 15px !important;
}

.listperfectly-dropdown-content-item:hover .photoroom-logo-second-part{
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}

.listperfectly-btn-icon:disabled{
    background-color: rgba(0,0,0,0) !important;
    opacity: 1 !important;
    cursor: default;
    border: none rgba(0, 0, 0, 0) !important;
}

.listperfectly-dropdown-content-item.disabled{
    background-color: #B6B6B6 !important;
    opacity: 1 !important;
    cursor: default !important;
    pointer-events:none !important;
    border: none rgba(0, 0, 0, 0) !important;
}

.listperfectly-dropdown-content-item.disabled span{
    color: #fff !important;
}
.listperfectly-dropdown-content-item.disabled .photoroom-dark-blue-image-icon{
    background: url(../images/eraser_actions.svg) no-repeat !important;
    background-size: 18px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        background-size: 14px !important;
    }
}

.listperfectly-dropdown-content-item.disabled .bulk-restore-button-icon{
    background: url(../images/restore_disabled_hover.svg) no-repeat !important;
    background-size: 15px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        background-size: 13px !important;
    }
}

.listperfectly-dropdown-content-item{
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.listperfectly-dropdown-content-item .background-limit-stats{
    font-size: 16px;
    margin-bottom: 4px;

    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 13px;
        margin-bottom: 3px;
    }
}

.listperfectly-dropdown-content-item:first-child{
    margin-top: 0;
}

.announce-link,.announce-message{
    font-size: 13px;
    position: relative;
    top: -14px;
    cursor: pointer;
}

.announce-message{
    cursor: default;
}

.old-uploader-warning-message{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 500px;
    padding: 18px 20px;
    background: rgb(255, 255, 255);
    border-radius: 8px;
}

.mobile-bottom-modal-container{
    overflow: hidden !important;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.mobile-bottom-modal{
    display: flex;
    z-index: 99999;
    flex-direction: column;
    position: absolute;
    bottom: -200px;
    align-items: flex-end;
    width: 100%;
    background: rgb(255, 255, 255);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.mobile-bottom-modal-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 34px 20px;
}
.mobile-bottom-modal-header{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-bottom-modal-header div{
    width: 60px;
    border: 1px solid #666;
    border-radius: 8px;
    margin-top: 8px;
}
.success-checkmark{
    width: 60px;
    height: 60px;
    background: url("../images/success_checkmark.svg") no-repeat;
    background-size: cover;
    cursor: pointer;
}
.old-uploader-warning-message-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.slide-top {
    -webkit-animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bottom {
    -webkit-animation: slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(200px);
        transform: translateY(200px);
    }
}
@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(200px);
        transform: translateY(200px);
    }
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
    }
}
@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
    }
}
.mobile-bottom-modal-headline{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: #1E3166;
}

.mobile-bottom-modal-body .btn{
    width: 252px;
    height: 50px;
    border-radius: 8px;
    box-shadow: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.mobile-bottom-modal-body .btn-green{
    margin-top: 52px;
    background: #4DB89E;
    border: 2px solid #4DB89E;
    text-transform: none !important;
}

.mobile-bottom-modal-body .btn-white-blue{
    border: 2px solid #1E3166;
    color: #1E3166;
    background: #fff;
    margin-top: 18px;
}

.old-uploader-warning-message-header span{
    display: block;
    font-size: 1.7em;
}

.close-icon-old-uploader-message{
    background-size: 22px !important;
}
.old-uploader-warning-message-footer{
    display: flex;
    flex-direction: row;
}

.old-uploader-warning-message-body{
    margin: 22px 0;
    font-size: 17px;
}

.old-uploader-warning-message-footer button:nth-child(2){
    margin-left: 24px;
}

.old-uploader-warning-message-footer button{
    margin-bottom: 0 !important;
}

.hideElement{
    display: none !important;
}

.bg-rm-color-picker-main-color{
    width: 60px;
    display: flex;
    align-items: center;
    cursor: pointer !important;
    align-content: center;
    justify-content: center;
    height: 39px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.bg-rm-color-picker-main-color > p{
    margin: 0 !important;
}

.iu-color-picker-item.selected {
    background-image: url("../images/checkmark.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.iu-color-picker-item.selected.black{
    background-image: url("../images/black_checkmark.svg") !important;
}


.iu-color-picker-item{
    height: 30px;
    width: 30px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2) !important;
    cursor: pointer;
    position: relative;
    outline: none;
    float: left;
    border-radius: 4px;
    margin: 0px 6px 6px 0px;
}
.chrome-picker > div:nth-child(2) > div:nth-child(2){
    display: none !important;
}
.iu-color-picker{
    background: rgb(255, 255, 255);
    border: 0px solid rgba(0, 0, 0, 0.25);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    border-radius: 4px;
    position: relative;
    padding: 15px 9px 9px 15px;
    width: 276px;
    top: -180px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.iu-color-picker.opened{
    top: -365px;
}


.chrome-picker{
    width: 98% !important;
    margin-bottom: 12px;
}

.iu-color-picker-opener{
    background: rgb(240, 240, 240);
    height: 30px;
    width: 30px;
    border-radius: 4px 0px 0px 4px;
    float: left;
    transition: 300ms;
    color: rgb(152, 161, 164);
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}
.iu-color-picker-opener:hover{
    background: rgb(201, 200, 200);
    color:  rgb(240, 240, 240);
}

body .iu-color-picker-colors > .iu-color-picker-input input{
    width: 108px !important; font-size: 14px !important; color: rgb(102, 102, 102) !important; border: 0px !important; outline: none !important; height: 30px !important;
    box-shadow: rgb(240, 240, 240) 0px 0px 0px 1px inset !important;
    border-radius: 0px 4px 4px 0px !important;
    float: left !important;
    padding: 0!important;
    padding-left: 8px !important;
    margin: 0 !important;
}

.bg-rm-color-picker-cover{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.twitter-picker span div {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2) !important;
}

.twitter-picker > div:last-child{
    padding: 15px 9px 52px 15px !important;
}

.lp-submit-small-btn,.lp-discard-small-btn{
    width: 100px !important;
    z-index: 999 !important;
    background-color: #234165 !important;
    -webkit-border-radius: 8px !important;
    border-radius: 8px !important;
    height: 39px !important;
    font-size: 16px !important;
    text-align: center !important;
    border: none !important;
    color: #fff !important;
    cursor: pointer !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    -webkit-align-items: center !important;
    align-items: center !important;
}

.lp-discard-small-btn{  background-color: #666 !important; }
.twitter-picker {
    top: -182px;
}
.ReactCrop__crop-mask{
    position: absolute;
    top: 0;
}
.ReactCrop__crop-selection {
    position: absolute;
    width: 100%;
    height: 100%;
}