.slider-custom-container .slick-dots {
	bottom: -55px;
	z-index: -1;
}
.slider-custom-container .slick-track {
	display: flex;
	gap: 0;
}

.slider-custom-container .slick-slide {
	margin-right: 15px;
}