.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 5px;
  right: 0;
  top: 0;
  border-right: 6px solid rgba(215, 226, 255, 0.5);
}

.resize-handle:hover {
  border-color: #ccc;
}

.resize-handle.active {
  border-color: #4db89e;
}

.myCheckbox {
  width: 25px !important;
}

.requestLogin {
  display: none !important;
}

#dropdown {
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
  width: 100%;
}
#catalogPageTable{
  border-bottom: 1px solid #d9d9d9;
}
#catalogPageTable thead tr  th:first-child {
  border-left: none ;
  justify-content: center ;
  align-items: center;
  border-right: 6px solid rgba(215, 226, 255, 0.5);

}
#catalogPageTable thead tr .sticky-element {
  border-left: none ;
  border-right: none ;
  border-top: 1px solid #D9D9D9 ;
  border-bottom: 1px solid #D9D9D9;
  font-weight: 600;
  color: #1E3166;
  flex-direction: column;
  gap: 5px;
  align-items: baseline;
  justify-content: left;
  transition: transform 0.1s ease-out;;
}
#catalogPageTable thead tr .sticky-element.sticky-checkbox-column {
  left: 0;
  z-index: 999;
}
#catalogPageTable thead tr .sticky-element .th-inner-container{
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
#catalogPageTable thead tr .sticky-element#sticky-image-col{
  position: sticky;
  left: 70px;
  z-index: 998;
}
#catalogPageTable thead tr .sticky-element .th-inner-container .lock-icon{
  height: 18px;
  width: 20px;
}
#catalogPageTable thead tr .sticky-element .th-inner-container .lock-icon svg{
  cursor: pointer;
  height: 20px !important;
  width: 20px !important;
  transform: none !important;
  margin: 0 !important;
}
.hiddenElementForTippyStickyColumns{
  visibility: hidden;
  position: absolute;
  top: -9999px;
}
.hiddenElementForTippyStickyColumns .tippy-box{
  background-color: #fff;
  border: 1px solid #cecece;
  font-weight: normal;
  color: #333;
  z-index: 9999 !important;
}
.hiddenElementForTippyStickyColumns .tippy-content{
  color: #333 !important;
}
#catalogPageTable thead tr .sticky-element .th-inner-container .lock-icon svg path {
  fill: rgba(30, 49, 102, 0.5);
}
#catalogPageTable thead tr .sticky-element .th-inner-container .lock-icon.sticky-column-active svg path{
  fill: #1E3166;
}

#catalogPageTable tbody td{
  border: none !important;
}
#catalogPageTable tbody td.activeColBorder{
  border-right: 6px solid rgba(215, 226, 255, 0.5) !important;
}
.sticky-element span p{
  color: #1E3166;
}

.socialIcon svg {
  width: 100%;
  height: 100%;
}

