@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif !important;
}

li {
    list-style: none !important;
}

a {
    text-decoration: none !important;
}

body {
    overflow-x: hidden !important;
    background: #f8f8f8 !important;
    font-family: 'Open Sans', sans-serif !important;
}

body::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

body::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

body::-webkit-scrollbar-track {
    background-color: #fff;
}

body::-webkit-scrollbar-button {
    display: none;
}

.lp_custom_chrome_extension_marketplace_listing_data{
    display: none;
}
.container {
    // max-width: 1250px;
    margin: 0 auto !important;
}

.page-container {
    /* max-width: 1440px !important; */
    margin: 0 auto !important;
    width: 100%;
    @media only screen and (max-width: 768px) {
      padding-bottom: 50px;
    }
}

.globalContainer {
    display: flex;
    margin: 0 auto !important;
    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;

        .listings-header-up {
            display: none;
        }
    }
}

.react-datepicker__input-container {
    input {
        font-family: 'Open Sans', sans-serif !important;
        background-color: transparent !important;
        height: 39px !important;
        outline: none !important;
        border: 1px solid $dark !important;
        border-radius: 4px !important;

        padding: 0 10px !important;

        &:focus {
            border: none !important;
            outline: 1px solid $mint !important;
        }

        &:disabled {
            outline: 1px solid $gray;
        }
    }
}

.myInputTextArea {
    font-family: 'Open Sans', sans-serif !important;
    background-color: transparent !important;
    height: 80%;
    width: 100%;
    font-size: 16px;
    outline: none !important;
    border: 1px solid #393838 !important;
    border-radius: 4px !important;
    padding: 0 10px !important;
    resize: none;
    border: none;

    &:focus {
        border: none !important;
        outline: 1px solid $mint !important;
        border-radius: 4px;
    }

    &:disabled {
        outline: 1px solid $gray;
        border-radius: 4px;
    }
}

.titleTextArea {
    font-family: 'Open Sans', sans-serif !important;
    background-color: transparent !important;
    height: 80%;
    width: 100%;
    font-size: 16px;
    outline: none !important;
    resize: none;
    border: none;
    padding: 0 10px 15px 10px !important;
}

.textAreaCounterContainer {
    &:focus-within {
        border: 1px solid $mint !important;
    }
}

.customMarketPlaceImage {
    width: 28px;

    @media (max-width: 768px) {
        width: 40px !important;
        /* New width for smaller screens */
    }
}

.myInput {
    font-family: 'Open Sans', sans-serif !important;
    background-color: transparent !important;
    height: 39px !important;
    outline: none !important;
    border: 1px solid $dark !important;
    border-radius: 4px !important;

    padding: 0 10px !important;

    &:focus {
        border: none !important;
        outline: 1px solid $mint !important;
    }

    &:disabled {
        outline: 1px solid $gray;
    }
}

.myInputSearchIcon {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #1e3166;
    pointer-events: none;
    width: 15px;

}

.SearchEverythingIcon {
    position: absolute;
    left: 10px;
    color: gray;
    pointer-events: none;
    width: 15px;

}

.myBtn {
    font-family: 'Open Sans', sans-serif !important;
    min-height: 39px !important;
    color: #fff !important;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    font-size: 16px !important;
    cursor: pointer !important;
    border: none !important;
    border-radius: 8px !important;
    font-weight: 600;
    transition: $transition;
    &:hover {
        opacity:$globalOpacity;
    }
}

.editBtn {
    font-family: 'Open Sans', sans-serif !important;
    background-color: $darkBlue !important;
    padding: 0 8px !important;

    &:hover {
        background: rgba(30, 49, 102, 0.8) !important;
    }
}

.cancelBtn {
    font-family: 'Open Sans', sans-serif !important;
    background-color: $gray !important;
    padding: 0 8px !important;
}

.iframeStyle {
    width: 80vw;
    margin-top: 37px;
    height: calc(100vh - 37px);
    border: none;

    @media (max-width: 768px) {
        width: 100vw !important;
        /* New width for smaller screens */
        height: 100% !important;
    }


}

.saveBtn {
    font-family: 'Open Sans', sans-serif !important;
    background-color: $mint !important;
    padding: 0 8px !important;

    &:hover {
        background-color: $green !important;
    }
}

.cancelBtn {
    font-family: 'Open Sans', sans-serif !important;
    background-color: $gray !important;
    padding: 0 5px !important;
}

.applyBtn {
    font-family: 'Open Sans', sans-serif !important;
    background-color: $darkBlue2 !important;
    padding: 0 10px !important;

    &:hover {
        background: rgba(30, 49, 102, 0.8) !important;
    }
}

.dangerCancelBtn {
    background: $dangerRed;
    padding: 8px 10px;
}

.dangerCancelBtnUpdated {
    background: white;
    border-radius: 8px;
    border: 1px solid var(--dark-blue, #1e3166) !important;
    color: #1e3166 !important;
    color: var(--dark-blue, #1e3166);
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.saveBtnUpdated {
    border-radius: 8px;
    border: 1px solid var(--mint, #4db89e);
    background: var(--mint, #4db89e);
    color: var(--white, #fff);
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.bulkbuttonsNavigation {
    display: grid;
    /* justify-content: space-around; */
    gap: 5px;
}

.bulkBtn {
    background-color: $mint;
    padding: 8px 15px;
}

.saveBulkBtn {
    background-color: $darkBlue;
    padding: 8px 18px;
}

.resizable-table {
    border-collapse: collapse;
}

.resizable-table td {
    //border: 1px solid #ccc;
    padding: 5px;
}

.hideElementOnScroll {
    width: 0 !important;
    height: 0 !important;
    padding: 0 !important;
    border: none !important;
    overflow: hidden !important;
}


#scrollHeader {
    position: sticky;
    top: 0;
    display: flex;
    z-index: 999;
    height: auto;
    padding: 10px 0;
    background-color: #f8f8f8;
    justify-content: space-between;
    align-items: center;
}

.resizable-table th {
    padding: 10px;
}

.resizable-handle {
    width: 8px;
    height: 100%;
    position: relative;
    background-color: #f0f0f0;
    right: -4px;
    cursor: col-resize;
    z-index: 1;
    height: 8px;
}

.react-resizable {
    position: relative;
}

.resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    right: -5px;
    bottom: 0;
    cursor: col-resize;
    z-index: 1;
    touch-action: none;
}

.resizable-handle:hover {
    background: rgba(0, 0, 0, 0.1);
}

input.no-spinner::-webkit-inner-spin-button,
input.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
}

.issueModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    /* Adjust the width as needed */
    width: 80%;
    /* Use a percentage or fixed width as per your design */
    border: 2px solid #fff4d3;
    border-radius: 10px;
    z-index: 999;
}
.issueModal button {
    cursor: pointer;
}

.issueModalOverlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(51, 51, 51, 0.4);
    z-index: 9999;
}
.issueModal .closeIcon {
    position: absolute;
    right: 5px;
    top: -8px;
    cursor: pointer;
    font-size: 25px;
}

.issueModal button {
    border: 1px solid #f31559;
    padding: 5px 10px;
    background: white;
    color: #f31559;
    font-size: 20px;
    border-radius: 5px;
}

.tooltip {
    background: white;
    border-radius: 10px;
    padding: 10px;
    visibility: hidden;
    position: absolute;
    margin-top: -5px;
    border: 1px solid #f4d160;
}

.popover {
    max-width: 300px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #cecece;
    z-index: 999;
    @media (min-width: 769px) and (max-width: 1440px) {
        max-width: 240px;
        border-radius: 8px;
        padding: 8px;
    }

}

.popoverCatalog{
    padding: 0 !important;
}

.sticky-element {
    z-index: 888;
    text-transform: capitalize;
}

.settingsIcon {
    fill: #1e3166 ;
}

.draftCheckbox {
    cursor: pointer;
    margin: 0 !important;
    -webkit-appearance: none;
    border: 1px solid #1e3166;
    border-radius: 8px;
    outline: none;
    width: 25px !important;
    height: 25px;
    font: initial;
    flex-shrink: 0;
    background-color: #fff;
    transition: $transition;

    &:hover {
        opacity: $globalOpacity;
    }

    &::after {
        content: ' ';
        display: block;
        background-size: 35px;
        background-repeat: no-repeat;
        background-position: center;
        left: 9px;
        top: 4px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: relative;
        width: 5px;
        height: 10px;
    }

    &:checked {
        background-color: #1e3166;
        border-color: #1e3166;
    }

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }
}

.idError path {
    fill: #FF4D4D;
}

.issueDot {
    background: red;
    height: 15px;
    position: absolute;
    top: -4px;
    right: -5px;
    width: 15px;
    border-radius: 8px;
    cursor: pointer;
}
.issueDot.issueDotSmall{
    background: red;
    height: 8px;
    position: absolute;
    top: -2px;
    left: 15px;
    width: 8px;
    border-radius: 8px;
    cursor: pointer;
    right: auto !important;
    bottom: auto !important;
}
@media (min-width: 769px) and (max-width: 1440px){
    .issueDot.issueDotSmall{
        top: -2px !important;
        left: 12px !important;
    }
}
.syncIssueDot{
    background: #FBBE0A;
    height: 15px;
    position: absolute;
    top: -4px;
    right: -5px;
    width: 15px;
    border-radius: 8px;
    cursor: pointer;
}
.syncIssueDot.syncIssueDotSmall{
    background: #FBBE0A;
    height: 8px;
    position: absolute;
    top: -2px;
    left: 15px;
    width: 8px;
    border-radius: 8px;
    cursor: pointer;
}
@media (min-width: 769px) and (max-width: 1440px){
    .syncIssueDot.syncIssueDotSmall{
        top: -2px !important;
        left: 12px !important;
    }
}
.issueTabActive path {
    fill: #fff;
}

.tippy-content {
    width: 300px;
}

.tippy-content:hover {
    cursor: text;
}

.navigationText {
    color: var(--dark-blue, #1e3166);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.acordionHeeder {
    border-radius: 8px;
    cursor: pointer;
    min-height: 42px;
    @media (min-width: 769px) and (max-width: 1440px) {
        min-height: 39px;
    }
}

.acordionHeederActive {
    cursor: pointer;
    min-height: 42px;
    @media (min-width: 769px) and (max-width: 1440px) {
        min-height: 39px;
    }

    .acordionFlex {
        border-radius: 8px;
        font-family: "Open Sans",'sans-serif';
        color:#1E3166;
        background:#E9EEFF;
        font-weight: 700 !important;
        @media (min-width: 769px) and (max-width: 1440px) {
            font-size: 14px;
            min-height: 39px;
        }
    }
}

.acordionFlex {
    display: flex;
    align-items: center;
    color: #1e3166;
    font-family: Open Sans, 'sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    transition: $transition;

    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 14px;
        padding: 8px;
        min-height: 39px;
        svg {
            width: 16px;
            height: 16px;
        }

    }
    &:hover {
        background: #E9EEFF;

        .pinnedIcon {
            opacity: 0.5;
        }
        &:active {
            background: #D6DAEA;
        }
    }
}
.acordionFlex.accordionFlexDisabled{
    color: #B6B6B6;
    svg{
        path{
            fill: #B6B6B6;
        }
    }
}
.acordionFlex.acordionFlexListings{
    padding: 10px;
    svg {
        @media (min-width: 769px) and (max-width: 1440px) {
            width: 16px;
            height: 16px;
        }
    }
}
.acrodionItem {
    color: #767676;
    font-family: Open Sans,'sans-serif';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    cursor: pointer;
    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 13px;
    }
}

.acrodionItemListing {
    color: #767676;
    font-family: Open Sans, 'sans-serif';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    padding: 10px;
    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 13px;
    }

}

.acrodionItemListingAddTemplate {
    border-radius: 8px;
    color: #767676;
    font-family: Open Sans, 'sans-serif';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    cursor: pointer;
    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 13px;
    }


    &:hover {
        background: #F5F5F5;
        color:#767676;
    }

    &:active {
        background: #ECECEC;
        color:#767676;
    }
}

.acrodionItemListingBulk {
    border-radius: 8px;
    background: #f5f5f5;
    color: #fff;
    font-family: Open Sans, 'sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    display: flex;
    border: 1px solid var(--dark-blue, #1e3166);
    background: var(--dark-blue, #1e3166);
}

.downSection {
    display: flex;
    flex-direction: column;
    padding-left: 26px;
    margin:5px 0;
}

.rototateArrow {
    transform: rotate(180deg);
}

.scrollContainer {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90vh;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #babac0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #c1c1c1;
    }

    &.scrollContainerSmall {
        gap: 34px;
    }
}

.resizable-table::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

.resizable-table::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

.resizable-table::-webkit-scrollbar-track {
    background-color: #fff;
}

.resizable-table::-webkit-scrollbar-button {
    display: none;
}

.selectInputDraftSold {
    cursor: pointer;
    margin: 0 !important;
    -webkit-appearance: none;
    border: 1px solid #1e3166;
    border-radius: 8px;
    outline: none;
    width: 25px !important;
    height: 25px !important;
    font: initial;
    flex-shrink: 0;
    background-color: #fff;

    &::after {
        content: ' ';
        display: block;
        background-size: 35px;
        background-repeat: no-repeat;
        background-position: center;
        left: 9px;
        top: 4px;

        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: relative;
        width: 5px;
        height: 10px;
    }

    &:checked {
        background-color: #1e3166;
        border-color: #1e3166;
    }

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }
}

.questionModal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.questionButton {
    border-radius: 8px;
    padding: 8px;
    border: 1px solid black;
}

.iframeOverflay {
    width: 100vw;
    height: 100vh;
    background: #27374d;
    position: fixed;
    z-index: 9993;
    opacity: 0.8;
}

.catalogPageTableWithNoResults {
    position: relative;
    padding-bottom: 100px;
}

.textAreaCounter {
    position: absolute;
    right: 5px;
    bottom: 1px;
    font-size: 13px;
    font-style: italic;
}

.notificationAbsoluteCloseButton {
    position: absolute;
    right: 13px;
    top: 10px;
    cursor: pointer;
    transform: scale(0.8);
}

.infinite-scroll-component__outerdiv {
    width: 100%;
    height: 100%;
}

.css-d7l1ni-option {
    cursor: pointer !important;
}

.css-tr4s17-option {
    cursor: pointer !important;
}

.issueTabDot {
    background: red;
    border-radius: 8px;
    height: 15px;
    width: 15px;
}

.selectorContainer {
    padding: 10px 20px;
    position: relative;
    z-index: 9;
}

// sales global

.react-datepicker-wrapper input {
    font-family: 'Open Sans', sans-serif !important;
    //width: fit-content !important;
    margin: 0 auto !important;
    background-color: transparent !important;
    height: 39px !important;
    border: 1px solid #393838 !important;
    // border-radius: 8px !important;
    border-radius: 4px !important;
    text-align: center !important;
}

.shopItem__wrapper {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    gap: 10px !important;
}

@media (max-width: 576px) {
    .shopItem__wrapper {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
        gap: 0;
    }
}

.saveBtnNotActive {
    cursor: not-allowed !important;
    font-family: "Open Sans", sans-serif !important;
    background-color: #b6b6b6 !important;
    padding: 8px 18px !important;
}

.mySelect {
    font-family: 'Open Sans', sans-serif !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    width: 158px !important;
    height: 39px !important;
    border-radius: 8px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    border: 1px solid #1e3166 !important;
}

@media (max-width: 1400px) {
    .mySelect {
        min-width: 100% !important;
    }
}

.pickList {
    display: flex;
    gap: 5px;
}

.mySelect li {
    -webkit-transition: 0.3s all !important;
    transition: 0.3s all !important;
}

.mySelect li:hover {
    background-color: #1e3166 !important;
    color: #fff !important;
}

.mySelect__opened {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-width: 0.1px !important;
    border-bottom: 0.1px solid transparent !important;
}

@media (max-width: 768px) {
    .jxltM6Z2tBla3Y3ws95as ._3weY74wu0-dKipgW7DkQH9 {
        position: static !important;
    }
}

@media (max-width: 768px) {
    .jxltM6Z2tBla3Y3ws95as ._3Aq3pDg2AycE7e2qPVCgiu ._1_k02M1Dwvc_6FUBu2gz6o ._1sT3WbQY_znGrifiSGRgQA {
        position: static !important;
    }
}

/* selects end start here */
/* CHECKBOX styles start here */
// .myCheckbox {
//   cursor: pointer !important;
//   margin: 0 !important;
//   -webkit-appearance: none !important;
//   border: 1px solid #1e3166 !important;
//   outline: none !important;
//   width: 25px !important;
//   height: 25px !important;
//   border-radius: 8px !important;
//   background-color: #fff;
//   font: initial !important;
//   -ms-flex-negative: 0;
//   flex-shrink: 0;
// }

// .myCheckbox::after {
//   content: " ";
//   display: block;
//   background-size: 35px;
//   background-repeat: no-repeat;
//   background-position: center;
//   left: 8px;
//   top: 3px;
//   width: 7px;
//   height: 14px;
//   position: relative;
// }

// .myCheckbox:checked {
//   background-color: #1e3166 !important;
//   border-color: #1e3166 !important;
// }

// .myCheckbox:checked::after {
//   border: solid #ffffff;
//   border-width: 0 2px 2px 0;
//   -webkit-transform: rotate(45deg);
//   transform: rotate(45deg);
// }

// .myCheckbox:focus {
//   outline: 0 !important;
// }

/* into site */
.notification-item {
    font-family: 'Open Sans', sans-serif !important;
    width: 100% !important;
    border-radius: 8px !important;
    padding: 18px 48px 18px 51px !important;
    min-height: 40px !important;
    -webkit-transition: 0.3s !important;
    transition: 0.3s !important;
    margin: 8px 0 !important;
    position: relative !important;
}

.sub-menu {
    padding: 15px 10px !important;
}

.pieContainer {
    width: 320px !important;
    padding: 10px !important;
    border-radius: 8px !important;
    border: 1px solid #393838 !important;
}

@media (max-width: 768px) {
    .pieContainer {
        width: 100% !important;
    }
}

@media (max-width: 576px) {
    .pieContainer {
        width: 100% !important;
    }
}

@media (max-width: 576px) {
    .pieContainer {
        margin-bottom: 0 !important;
    }
}

.verticalBarContainer {
    width: 600px;
    height: 300px;
}

@media (max-width: 768px) {
    .verticalBarContainer {
        width: 500px !important;
        height: 250px !important;
    }
}

@media (max-width: 576px) {
    .verticalBarContainer {
        width: 400px !important;
        height: 200px !important;
    }
}

.samePlatform {
    opacity: 0.4 !important;
    pointer-events: none !important;
}

.datepicker__container {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    gap: 12px !important;
}

.datepicker__container .datePicker__wrapper {
    display: -webkit-box ;
    display: -ms-flexbox ;
    display: flex ;
    width: 250px ;
    border: 1px solid #1e3166 ;
    border-radius: 4px ;

    height: 39px ;
}

.datepicker__container .datePicker__wrapper span {
    font-size: 25px !important;
    margin: auto !important;
}

.datepicker__container .datePicker__wrapper .react-datepicker-wrapper {
    width: 50% !important;
}

.datepicker__container .datePicker__wrapper .react-datepicker-wrapper input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    outline: none !important;
    border: none !important;
    text-align: center !important;
    font-size: 16px ;
    width: 100% !important;
}

.datepicker__container .datePicker__wrapper .react-datepicker-wrapper input:focus {
    outline: none !important;
    border: none !important;
}

.react-datepicker-popper {
    z-index: 999!important;
}

@media (max-width: 768px) {
    .datepicker__container .datePicker__wrapper .react-datepicker-wrapper input {
        margin-left: 0 !important;
        text-align: center !important;
    }
}

.datepicker__container .datePicker__wrapper:active {
    border: 1px solid #4db89e !important;
}

@media (max-width: 768px) {
    .datepicker__container .datePicker__wrapper {
        width: 100% !important;
    }
}

@media (max-width: 320px) {
    .datepicker__container .datePicker__wrapper {
        width: calc(100vw - 40px) !important;
    }
}

@media (max-width: 768px) {
    .datepicker__container {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
        -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }
}

@media (max-width: 768px) {
    .datepicker__container button {
        -webkit-transform: none !important;
        transform: none !important;
    }
}

.datepickerNOTLABEL__container .datePicker__wrapper {
    display: -webkit-box ;
    display: -ms-flexbox ;
    display: flex ;
    width: 250px ;
    border: 1px solid #1e3166 ;
    border-radius: 4px ;
    height: 39px ;
}

.datepickerNOTLABEL__container .datePicker__wrapper span {
    font-size: 25px !important;
    margin: auto !important;
}

.datepickerNOTLABEL__container .datePicker__wrapper .react-datepicker-wrapper {
    width: 50%;
}

.datepickerNOTLABEL__container .datePicker__wrapper .react-datepicker-wrapper input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    outline: none !important;
    border: none !important;
    text-align: center !important;
    font-size: 16px;
    width: 100% !important;
}

.datepickerNOTLABEL__container .datePicker__wrapper .react-datepicker-wrapper input:focus {
    outline: none !important;
    border: none !important;
}

@media (max-width: 768px) {
    .datepickerNOTLABEL__container .datePicker__wrapper .react-datepicker-wrapper input {
        margin-left: 0 !important;
        text-align: center !important;
    }
}

.datepickerNOTLABEL__container .datePicker__wrapper:active {
    border: 1px solid #4db89e !important;
}

@media (max-width: 768px) {
    .datepickerNOTLABEL__container .datePicker__wrapper {
        width: 100% !important;
    }
}

@media (max-width: 320px) {
    .datepickerNOTLABEL__container .datePicker__wrapper {
        width: calc(100vw - 40px) !important;
    }
}


@media (max-width: 768px) {
    .container {
        width: calc(100vw - 40px) !important;
    }
}

.overflow-hidden {
    overflow: hidden;
}

#syncButton {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 5px;
}

.myInputCatalog {
    padding: 0 10px;
    height: 39px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
    color: #393838;
    outline: none;
    width: 100%;
}

.myInputCatalog:focus {
    border: 1px solid #1e3166;
}

.disabledTab {
    display: none !important;
    gap: 5px !important;
    cursor: pointer !important;
    flex-direction: column !important;
    align-items: center !important;
    background-color: #b4b4b3 !important;
    padding: 10px 15px !important;
    border-radius: 4px !important;
}

.disabledTab span {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
    color: #fff !important;
    display: flex !important;
    align-items: center !important;
}


.lp_custom_marketplaces_sync__start.active {
    display: block !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 600;
    min-height: 39px !important;
    transition: all .3s !important;
    padding: 8px 15px;
    margin-right: 15px;
    font-family: Open Sans, sans-serif !important;
    background-color: #4db89e;
}

.lp_custom_marketplaces_sync__start.hidden {
    display: none !important;
}

.lp_custom_marketplaces_sync__cancel.active {
    display: block !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 600;
    min-height: 39px !important;
    transition: all .3s !important;
    padding: 8px 15px;
    margin-right: 15px;
    font-family: Open Sans, sans-serif !important;
    background: #ff5353 !important;
}

.lp_custom_marketplaces_sync__cancel.hidden {
    display: none !important;
}

.lp_custom_marketplaces_sync__in_progress.active {
    display: block !important;
    border: none !important;
    border-radius: 8px !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 600;
    min-height: 39px !important;
    transition: all .3s !important;
    padding: 8px 15px;
    margin-right: 15px;
    font-family: Open Sans, sans-serif !important;
    background-color: #FCD45F !important;
}

.lp_custom_marketplaces_sync__in_progress.hidden {
    display: none !important;
}



// intercom red dot
.edrs4yi0 {
    @media only screen and (max-width: 768px){
        display: none !important;
    }
}



@media (min-width: 769px) and (max-width: 1440px)  {
    body {
        font-size: 13px !important;
    }

    .myBtn {
        font-size: 13px !important;
        border-radius: 6px !important;
        min-height: 32px !important;
    }

    .bulkBtn {
        padding: 6px 12px !important;
    }

    .dangerCancelBtn {
        padding: 6px 8px;
    }

    .saveBulkBtn {
        padding: 6px 14px !important;
    }

    .resizable-table th {
        padding: 8px;
    }

    .tippy-content {
        width: 240px;
    }

    .issueTabDot {
        border-radius: 6px;
        height: 12px;
        width: 12px;
    }

    .myInput {
        height: 32px !important;
        padding: 0 8px !important;
    }

    .react-datepicker-wrapper input {
        height: 32px !important;
        padding: 0 8px !important;
    }

    .SearchEverythingIcon {
        position: absolute;
        left:8px;
        color: gray;
        pointer-events: none;
        width: 12px;
    }

	.applyBtn {
		padding: 0 8px !important;
		gap:4px;
	}

	.pickList {
		svg {
			width: 16px;
			height: 16px;
		}
	}

	.datepicker__container , .datePicker__wrapper {
		width: 200px !important;
        height: 32px !important;
	}


	.datepickerNOTLABEL__container .datePicker__wrapper .react-datepicker-wrapper input {
		font-size: 13px;
	}

    .draftCheckbox {
        border-radius: 6px;
        width: 20px !important;
        height: 20px !important;

        &::after {
            left: 7px;
            top: 3px;
            border-width: 0 1px 1px 0;
            width: 4px;
            height: 8px;
        }
    }

    .issueDot {
        height: 12px;
        top: -3px;
        right: 2px;
        width: 12px;
        border-radius: 6px;
    }

    .syncIssueDot{
        height: 12px;
        top: -3px;
        right: 2px;
        width: 12px;
        border-radius: 6px;
    }

	.selectInputDraftSold {

		border-radius: 6px !important;
		width: 20px !important;
		height: 20px !important;

		&::after {
			left: 7px;
			top: 3px;
			border-width: 0 1px 1px 0;
			width: 4px;
			height: 8px;
		}

	}

	.myInputCatalog {
		padding: 0 8px;
		height: 32px;
		border-radius: 3px;
	}

	.myInputSearchIcon {
		top: 40%;
		left: 4px;
		width: 12px;
	}
}
