@import '../../../../../../assets/styles/variables.scss';

.tableSettings__overlay {
  font-family: $fontFamilyOpenSans !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .clearSelection{
    margin-top: 10px;
    max-width: 170px;
  }
  .myCheckbox {
    cursor: pointer;
    margin: 0 !important;
    -webkit-appearance: none;
    border: 1px solid #1e3166;
    border-radius: 8px;
    outline: none;
    width: 25px;
    height: 25px;
    font: initial;
    flex-shrink: 0;
    background-color: #fff;

    &::after {
      content: ' ';
      display: block;
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center;
      left: 9px;
      top: 4px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: relative;
      width: 5px;
      height: 10px;
    }

    &:checked {
      background-color: #1e3166;
      border-color: #1e3166;
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    border-radius: 8px;
    background-color: #fff;
    width: 1000px;
    padding: 30px 30px 20px 30px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--that_grey);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #c1c1c1;
    }

    .modal__title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-transform: capitalize;
      color: #393838;
      margin-bottom: 10px;
    }

    .modal__wrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .columns__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #393838;
        margin-bottom: 10px;
      }

      .modal__viewColumns {
        .modal__viewColumns_wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 30px;
          column-count: 3;

          .viewColumnItem {
            break-inside: avoid;
            display: flex;
            align-items: center;
            gap: 10px;
            user-select: none;
            width: fit-content;
            transition: $transition;

            &:hover {
              opacity: $globalOpacity;
            }
            label {
              white-space: nowrap;
              cursor: pointer;

            }
          }
        }
      }

      margin-bottom: 50px;
    }

    .arrange__block {
      .arrange__items {
        display: flex;
        align-items: center;
        gap: 10px;

        .arrange__wrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 250px;
          background: #ffffff;
          border: 1px solid #b6b6b6;
          border-radius: 8px;
          padding: 10px;
          max-height: 250px;
          overflow-y: auto;
          span {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-transform: capitalize;
            color: #393838;
            padding-top:3px;
            padding-bottom: 3px;
            padding-left: 10px;
            padding-right: 10px;
          }

          .activeSpan {
            color: #fff;
            font-weight: 600;
            border-radius: 8px;
            padding-left: 10px;
            padding-right: 10px;
            background-color: $darkBlue;
          }
        }

        .arrange__directions {
          display: flex;
          flex-direction: column;
          gap: 5px;
          user-select: none;
          span {
            cursor: pointer;
            background: #ffffff;
            border: 1px solid #1e3166;
            border-radius: 8px;
            padding: 15px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition:$transition;

            &:hover {
              opacity: $globalOpacity;
            }

            &:last-child {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .tableSettingFooter {
      .modal__btn__block {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 22px;
        user-select: none;

        div {
          display: flex;
          gap: 15px;

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            text-transform: capitalize;
            color: #1e3166;
            cursor: pointer;

            &:first-child {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1440px)  {
  .myCheckbox {
    width: 20px !important;
    height: 20px !important;
    border-radius:6px !important;

    &::after {
      left: 7px !important;
      top: 3px !important;
      width: 4px !important;
      height: 8px !important;
      border-width: 0 1px 1px 0 !important;
    }
  }

  .modal {
    width: 800px !important;
    padding: 24px 24px 16px 24px !important;
    border-radius: 6px !important;

    .modal__title {
      font-size: 16px !important;
      line-height: 22px !important;
    }

    .columns__title {
      font-size: 13px !important;
      line-height: 18px !important;
      margin-bottom: 8px !important;
    }

    .modal__viewColumns_wrapper {
      column-gap: 24px !important;

      .viewColumnItem {
        gap: 8px !important;
      }
    }

    .arrange__items {
        gap: 8px !important;

      .arrange__wrapper {
        gap: 4px !important;
        padding: 8px !important;
        max-height:200px !important;
        border-radius: 6px !important;
        width: 200px !important;

        span {
          padding: 2px 8px !important;
          font-size: 13px !important;
          line-height: 18px !important;
        }

        .activeSpan {
          padding-left: 8px !important;
          padding-right: 8px !important;
        }
      }

      .arrange__directions {
        gap: 4px !important;

        span {
          padding: 12px 8px !important;
          border-radius: 6px !important;

          svg {
            width: 18px !important;
            height: 8px !important;
          }
        }
      }
    }

    .tableSettingFooter {
      .modal__btn__block {
        gap: 18px !important;
        div {
          gap: 12px !important;

          span {
            font-size: 13px !important;
          }
        }
      }
    }

  }
}